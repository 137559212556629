import React from 'react';
import { toast } from 'react-toastify';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Icon } from '@components';
import { ReactComponent as SuccesIcon } from '@assets/ToastSuccessIcon.svg';
import { ReactComponent as ErrorIcon } from '@assets/ToastErrorIcon.svg';
import { SUBSCRIPTION_REDIRECT_IDS, TRACKING_PRODUCT_TYPES } from '../constants/common';

const createCommonAsyncThunk = (name, request) => createAsyncThunk(
  name,
  async (body, { rejectWithValue }) => {
    const { response, error } = await request(body || null);
    if (error) {
      return rejectWithValue(error);
    }

    return response;
  },
);

const removePrefix = (text, prefix) => {
  if (!text) {
    return text;
  }

  let processedText = text;
  if (text.includes(prefix)) {
    processedText = text.replace(prefix, '').trim();
  }

  return processedText;
};

const showErrorToast = text => {
  toast.error(text, { icon: <Icon SVG={ErrorIcon} width="20px" height="20px" /> });
};

const showSuccessToast = text => {
  toast.success(text, { icon: <Icon SVG={SuccesIcon} width="20px" height="20px" /> });
};

const isEmpty = obj => obj
  && Object.keys(obj).length === 0
  && Object.getPrototypeOf(obj) === Object.prototype;

const calculateDiscount = ({ amountOff, percentOff, price }) => {
  if (amountOff) {
    return amountOff;
  }

  return Math.round(price * (percentOff / 100));
};

const getCookieByName = cookieName => {
  const cookies = document.cookie.split('; ');

  if (cookies) {
    const cookieByName = cookies.find(c => c.startsWith(cookieName));
    const cookieValue = cookieByName?.split('=')?.[1];

    return cookieValue ?? '';
  }

  return '';
};

const clearGtmDataLayer = () => {
  // eslint-disable-next-line func-names
  window.dataLayer.push(function () {
    // eslint-disable-next-line react/no-this-in-sfc
    this.reset();
  });
};

const trackGTMViewItem = ({ subscription, customer }) => {
  clearGtmDataLayer();

  window.dataLayer.push({
    event: 'view_item',
    eventProps: {
      currency: 'NOK',
      value: subscription.price / 100,
      items: [{
        item_id: subscription.id,
        item_name: subscription.title,
        item_category: TRACKING_PRODUCT_TYPES.subscription,
      }],
    },
    meta: {
      email: customer.email,
      eventProps: {
        content_type: 'product',
        content_name: subscription.title,
        content_ids: [subscription.id],
        contents: [{
          id: subscription.id,
          quantity: 1,
        }],
        currency: 'NOK',
        value: subscription.price / 100,
      },
    },
    userProps: {
      user_id: customer.id,
      subscription_id: subscription.id,
      subscription_name: subscription.title,
      subscription_price: subscription.price / 100,
    },
  });
};

const getSubscription = (id, allSubscriptions) => {
  const redirectSubscriptionId = SUBSCRIPTION_REDIRECT_IDS[+id];

  return allSubscriptions.find(
    subscription => subscription.id === +id || subscription.id === redirectSubscriptionId,
  );
};

const formatSubscriptionPrice = (price, currency = 'kr') => `${price / 100} ${currency}`;

const formatSubscriptionTitle = (subscription, language) => (language === 'en'
  ? removePrefix(subscription?.title, 'Secondary')
  : removePrefix(subscription?.title_no, 'Secondary'));

export {
  createCommonAsyncThunk,
  showErrorToast,
  showSuccessToast,
  isEmpty,
  calculateDiscount,
  removePrefix,
  getCookieByName,
  clearGtmDataLayer,
  trackGTMViewItem,
  getSubscription,
  formatSubscriptionPrice,
  formatSubscriptionTitle,
};
