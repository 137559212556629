import { bool, func, string } from 'prop-types';
import React from 'react';
import { useWindowSize } from '@hooks';
import { Label, Container } from './styles';

const Chip = ({ label, onClick, isActive }) => {
  const [isMobile] = useWindowSize();

  return (
    <Container isMobile={isMobile} isActive={isActive} onClick={onClick}>
      <Label isMobile={isMobile} isActive={isActive}>
        {label}
      </Label>
    </Container>
  );
};

Chip.propTypes = {
  isActive: bool,
  label: string.isRequired,
  onClick: func,
};

Chip.defaultProps = {
  isActive: false,
  onClick: () => {},
};

export default Chip;
