import { apiClient } from '@utils';

import { CUSTOMER_ENDPOINTS, HTTP_METHODS } from '@constants';

const {
  CUSTOMER_BY_EMAIL, CUSTOMER,
} = CUSTOMER_ENDPOINTS;

const { GET, POST } = HTTP_METHODS;

const getCustomerByEmail = params => apiClient.unauthorizedRequest({
  method: GET,
  url: CUSTOMER_BY_EMAIL,
  params,
});

const createCustomer = data => apiClient.unauthorizedRequest({
  method: POST,
  url: CUSTOMER,
  data,
});

const api = {
  getCustomerByEmail,
  createCustomer,
};

export default api;
