const EMAIL_REGEX = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const URL_REGEX = /^(http|https):\/\/(.*)/;
const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{6,}$/;
const NUMBER_REGEX = /[^0-9.]/g;

export {
  EMAIL_REGEX,
  URL_REGEX,
  PASSWORD_REGEX,
  NUMBER_REGEX,
};
