import { apiClient } from '@utils';

import { PRODUCTS_EDPOINTS, HTTP_METHODS } from '@constants';

const {
  PRODUCTS,
  PURCHASES,
  CREATE_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  RESTORE_SUBSCRIPTION,
  CREATE_CHECKOUT_SESSION,
  CHECK_PROMOCODE,
  SUBSCRIPTIONS,
} = PRODUCTS_EDPOINTS;

const { GET, POST } = HTTP_METHODS;

const getProducts = params => apiClient.authorizedRequest({
  method: GET,
  url: PRODUCTS,
  params,
});

const getAllSubscriptions = () => apiClient.unauthorizedRequest({
  method: GET,
  url: SUBSCRIPTIONS,
});

const getUserPurchases = () => apiClient.authorizedRequest({
  method: GET,
  url: PURCHASES,
});

const createStripeSubscription = ({ query, data }) => apiClient.unauthorizedRequest({
  method: POST,
  url: CREATE_SUBSCRIPTION,
  params: query,
  data,
});

const createCheckoutSession = data => apiClient.unauthorizedRequest({
  method: POST,
  url: CREATE_CHECKOUT_SESSION,
  data,
});

const cancelSubscription = () => apiClient.authorizedRequest({
  method: POST,
  url: CANCEL_SUBSCRIPTION,
});

const restoreSubscription = () => apiClient.authorizedRequest({
  method: POST,
  url: RESTORE_SUBSCRIPTION,
});

const checkPromocode = data => apiClient.unauthorizedRequest({
  method: POST,
  url: CHECK_PROMOCODE,
  data,
});

const api = {
  getProducts,
  getUserPurchases,
  createStripeSubscription,
  cancelSubscription,
  restoreSubscription,
  createCheckoutSession,
  checkPromocode,
  getAllSubscriptions,
};

export default api;
