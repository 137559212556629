import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  Flex, Typography, Icon, DeleteAccountModal,
} from '@components';
import { STORE_NAMES } from '@constants';
import { logout } from '@store';
import { routes } from '@utils';
import { clearLocalStorage, setUserLanguage } from '@utils/storage';

import { ReactComponent as NorwayFlag } from '@assets/NorwayFlagIcon.svg';
import { ReactComponent as LogoutIcon } from '@assets/LogoutIcon.svg';
import { ReactComponent as ArrowUp } from '@assets/ArrowUp.svg';
import { ReactComponent as ArrowDown } from '@assets/ArrowDown.svg';
import { ReactComponent as UnitedKingdomFlag } from '@assets/UnitedKingdomFlag.svg';

const MenuDropdown = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false);

  const { authorization_info } = useSelector(store => store[STORE_NAMES.AUTH]);

  const handleMenuToggle = () => setIsMenuOpen(prev => !prev);

  const handleLogout = async () => {
    await dispatch(logout());
    clearLocalStorage();
    setIsMenuOpen(false);
    history.push(routes.subscriptions);
  };

  const handleSwitchLanguage = () => {
    const selectedLng = i18n.language === 'en' ? 'no' : 'en';
    i18n.changeLanguage(selectedLng);
    setUserLanguage(selectedLng);
  };

  const handleDeleteAccountModalClose = () => {
    setIsDeleteAccountModalOpen(false);
  };

  const handleDeleteAccountModalOpen = event => {
    event.preventDefault();

    setIsDeleteAccountModalOpen(true);
    setIsMenuOpen(false);
  };

  return (
    <Flex column grow="0" position="relative">
      <DeleteAccountModal
        isOpen={isDeleteAccountModalOpen}
        onClose={handleDeleteAccountModalClose}
      />
      <Flex
        onClick={handleMenuToggle}
        cursor="pointer"
        height="48px"
        itemsCenter
      >
        <Typography
          marginRight="sm"
          color="darkSecondary"
        >
          {authorization_info?.email || authorization_info?.fullname}
        </Typography>
        <Icon SVG={isMenuOpen ? ArrowUp : ArrowDown} width="24px" height="24px" />
      </Flex>
      {isMenuOpen && (
        <Flex
          column
          position="absolute"
          top="48px"
          right="0"
          width="240px"
          paddingRight="sm"
          paddingLeft="sm"
          borderRadius="sm"
          bgColor="white"
          shadow="default"
        >
          <Flex
            onClick={handleSwitchLanguage}
            cursor="pointer"
            height="48px"
            itemsCenter
            selfEnd
          >
            <Typography marginRight="sm" color="darkSecondary">{t('AuthCommonContent.SwitchLanguage')}</Typography>
            <IconWrapper borderRadius="xs">
              <Icon SVG={i18n.language === 'en' ? NorwayFlag : UnitedKingdomFlag} width="24px" height="18px" />
            </IconWrapper>
          </Flex>
          <Flex
            onClick={handleLogout}
            cursor="pointer"
            height="48px"
            itemsCenter
            selfEnd
          >
            <Typography marginRight="sm" color="darkSecondary">{t('Subscriptions.Logout')}</Typography>
            <Icon SVG={LogoutIcon} />
          </Flex>
          <Flex
            onClick={handleDeleteAccountModalOpen}
            cursor="pointer"
            height="48px"
            itemsCenter
            selfEnd
          >
            <Typography marginRight="sm" color="redDark">
              {t('DeleteAccount.Title')}
            </Typography>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

const IconWrapper = styled(Flex)`
    overflow: hidden;
`;

export default MenuDropdown;
