import { css, createGlobalStyle } from 'styled-components';

const fontFace = (family, src, weight) => css`
  @font-face {
    font-family: ${family};
    src: url('/fonts/${src}.eot') format('embedded-opentype'),
      url('/fonts/${src}.ttf') format('truetype'),
      url('/fonts/${src}.woff') format('woff');
    font-display: swap;
    font-weight: ${weight};
  }
`;

const FontStyle = createGlobalStyle`
  ${fontFace('Poppins', 'Poppins-Bold', 700)};
  ${fontFace('Poppins', 'Poppins-Medium', 500)};
  ${fontFace('Poppins', 'Poppins-Regular', 400)};
`;

export default FontStyle;
