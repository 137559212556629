import styled, { css } from 'styled-components';
import { marginMixin, paddingMixin } from '@mixins';

const {
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
} = marginMixin;

const {
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
} = paddingMixin;

const Button = styled.button`
${({
    fullWidth,
    width,
    minWidth,
    maxWidth,
    height,
    minHeight,
    maxHeight,
    color,
    bgColor,
    borderRadius,
    hoverEffect,
    activeEffect,
    flexBasis,
    type,
    theme: {
      color: colorFromTheme, border, typography,
    },
  }) => css`

    ${margin}
    ${marginTop}
    ${marginRight}
    ${marginBottom}
    ${marginLeft}

    ${padding}
    ${paddingTop}
    ${paddingRight}
    ${paddingBottom}
    ${paddingLeft}

    ${fullWidth && `
      width: 100%;
    `}

    ${width && `
      width: ${width};
    `}

    ${minWidth && `
      min-width: ${minWidth};
    `}

    ${maxWidth && `
      max-width: ${maxWidth};
    `}

    ${height && `
      height: ${height};
    `}

    ${minHeight && `
      min-height: ${minHeight};
    `}

    ${maxHeight && `
      max-height: ${maxHeight};
    `}

    ${flexBasis && `
      flex-basis: ${flexBasis};
    `}
  
    line-height: normal;
    outline: none;
    transition: all 0.3s;
    cursor: pointer;

    :hover {
      ${hoverEffect || `background: ${colorFromTheme.greenHover}`};
    }

    :active {
      ${activeEffect || `background: ${colorFromTheme.greenActive}`};
    }

    :disabled {
      background: ${colorFromTheme.greenDisabled};
    }
  
    color: ${colorFromTheme[color] || colorFromTheme.white};
    background-color: ${colorFromTheme[bgColor]};
    border: 1px solid ${colorFromTheme.lightGrey};
    border-radius: ${border.radius[borderRadius] || border.radius.default};
    font-family: ${typography.family};
    font-size: ${typography.size.lg};
    font-weight: ${typography.weight.regular};
    
    ${type === 'text' && css`
        background: transparent;
        border: none;
        padding: 0;
     :hover, :active, :focus, :disabled {
        background: transparent;
     }
    `}

    ${type === 'secondary' && css`
        background: ${colorFromTheme.white};
        color: ${colorFromTheme.black};
      :hover, :active, :focus, :disabled {
        background: ${colorFromTheme.white};
      }
    `}
    
  `}
`;

export default Button;
