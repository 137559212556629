import React from 'react';
import { useSelector } from 'react-redux';

import { Flex, UserSubscriptionBlock } from '@components';
import { STORE_NAMES } from '@constants';

const UserSubscriptionTab = () => {
  const { userPurchases: { subscription } } = useSelector(store => store[STORE_NAMES.PRODUCTS]);

  return (
    <Flex marginTop="xl4">
      <UserSubscriptionBlock subscription={subscription} />
    </Flex>
  );
};

export default UserSubscriptionTab;
