import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Icon } from '@components';
import { ReactComponent as SearchIcon } from '@assets/SearchIcon.svg';
import { func, string } from 'prop-types';
import styled, { css } from 'styled-components';

const SearchBar = ({ value, onChange }) => {
  const { t } = useTranslation();

  return (
    <Flex position="relative" grow="0">
      <StyledSearchInput type="text" placeholder={t('Products.Search')} value={value} onChange={onChange} />
      <SearchIconWrapper>
        <Icon SVG={SearchIcon} />
      </SearchIconWrapper>
    </Flex>
  );
};

SearchBar.propTypes = {
  value: string,
  onChange: func,
};

SearchBar.defaultProps = {
  value: '',
  onChange: () => {},
};

const StyledSearchInput = styled.input`
    ${({ theme: { typography, border } }) => css`
        height: 40px;
        width: 100%;
        padding: 10px 16px;
        border: none;
        border-radius: ${border.radius.searchBar};
        background-color: rgba(107, 120, 136, 0.1);
        outline: none;
        font-family:  ${typography.family};

        ::-webkit-input-placeholder {
            font-family: ${typography.family};
        }
    `}
`;

const SearchIconWrapper = styled(Flex)`
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
`;

export default SearchBar;
