import { Typography } from '@components';
import React from 'react';
import { string } from 'prop-types';
import { useWindowSize } from '@hooks';
import { Container, Image, TextContainer } from './styles';

const FeatureCard = ({ title, description, imageSrc }) => {
  const [isMobile] = useWindowSize();

  return (
    <Container isMobile={isMobile}>
      <Image src={imageSrc} alt="" isMobile={isMobile} />
      <TextContainer>
        <Typography size="xl2" weight="medium">{title}</Typography>
        <Typography size="md" maxWidth="204px">
          {description}
        </Typography>
      </TextContainer>
    </Container>
  );
};

FeatureCard.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  imageSrc: string.isRequired,
};

export default FeatureCard;
