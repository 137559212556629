import styled, { css } from 'styled-components';

const Spinner = styled.div`
    ${({
    size, color, isLoading, theme: { border },
  }) => css`
    border: 2px solid transparent;
    border-top: 2px solid ${color || 'white'};
    border-radius: ${border.radius.circular};
    width: ${size || '30px'};
    height: ${size || '30px'};
    animation: spin 1.5s linear infinite;
    opacity: ${isLoading ? '1' : '0'};
     
    
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    `}
`;

export default Spinner;
