import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import {
  Flex, Typography, UserSubscriptionBlock,
} from '@components';
import { STORE_NAMES } from '@constants';
import { PRODUCT_TYPES } from '@constants/common';
import { getProducts } from '@store';

const PurchasesDesktop = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const {
    userPurchases: {
      subscription,
      non_consumable_products,
      consumable_products,
    },
  } = useSelector(store => store[STORE_NAMES.PRODUCTS]);

  useEffect(() => {
    dispatch(getProducts());
  }, []);

  const products = useMemo(
    () => [...non_consumable_products, ...consumable_products],
    [non_consumable_products, consumable_products],
  );

  const handleProductType = type => {
    switch (type) {
      case PRODUCT_TYPES.booklet:
        return t('Products.Booklet');
      case PRODUCT_TYPES.document:
        return t('Products.E-book');
      case PRODUCT_TYPES.trainingProgram:
        return t('Products.TrainingProgram');
      case PRODUCT_TYPES.dietPlan:
        return t('Products.DietPlan');
      default:
        return 'Product';
    }
  };

  return (
    <Flex column marginTop="56px" maxWidth="1440px">
      <Typography
        weight="bold"
        size="xl"
        color="darkSecondary"
        marginBottom="xl6"
      >
        {t('Purchases.Title')}
      </Typography>
      <Flex justifyBetween>
        <Flex width="50%" grow="0" column marginRight="xl6">
          <Typography color="greyApple" size="xl" marginBottom="xl4">{t('Purchases.MySubscription')}</Typography>
          <UserSubscriptionBlock subscription={subscription} />
        </Flex>
        <Flex width="50%" column>
          <Typography color="greyApple" size="xl" marginBottom="xl4">{t('Purchases.MyProducts')}</Typography>
          {products?.length ? (
            <Flex column shadow="light" grow="0">
              {products.map(product => {
                const productLink = i18n.language === 'no' ? product.link_no : product.link_eng;
                return (
                  <BorderedWrapper key={product.id} itemsCenter padding="xl4" grow="0">
                    <Flex column>
                      <Typography size="md" color="greyApple">
                        {handleProductType(product.type)}
                      </Typography>
                      <Typography size="lg" color="darkSecondary">{product.name}</Typography>
                    </Flex>
                    {product.type === PRODUCT_TYPES.document && (
                      <DownloadLink href={productLink} download target="_blank" rel="noreferrer">
                        {t('Purchases.Download')}
                      </DownloadLink>
                    )}
                  </BorderedWrapper>
                );
              })}
            </Flex>
          ) : (
            <Flex column shadow="light" justifyCenter>
              <Typography color="darkSecondary" textAlign="center">{t('Purchases.ProductsPlaceholder')}</Typography>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

const BorderedWrapper = styled(Flex)`
    &:not(:last-child) {
        border-bottom: 1px solid rgba(107, 120, 136, 0.1);
    }
`;

const DownloadLink = styled.a`
    ${({ theme: { typography } }) => css`
    font-family: ${typography.family};
    font-size: 14px;
    color: #1bc98e;
    text-decoration: none;
  `}
`;

export default PurchasesDesktop;
