import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  Button, Flex, Icon, Typography,
} from '@components';
import { routes } from '@utils';
import { ReactComponent as Logo } from '@assets/Logo.svg';
import notFound from '@assets/notFound.png';
import { useWindowSize } from '@hooks';

const NotFoundScreen = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isMobile, isDesktop] = useWindowSize();

  const handleGoHome = () => {
    history.push(routes.subscriptions);
  };

  return (
    <Flex grow="0" height="100vh">
      <Flex column padding="20px 40px" width="50%">
        <Flex
          grow="0"
          column
          onClick={handleGoHome}
          width="min-content"
          cursor="pointer"
          marginBottom="50px"
        >
          <Icon
            SVG={Logo}
            width="180px"
            height="48px"
          />
        </Flex>
        <Flex column grow="0">
          <Flex
            column
            grow="0"
            marginBottom="xl"
            maxWidth={isDesktop ? '100%' : '500px'}
          >
            <Typography
              bold
              size={isMobile ? 'xxl' : 'xxxl'}
              lineHeight="211px"
              marginBottom="xl"
            >
              {t('NotFoundScreen.Title')}
            </Typography>
            <Typography
              bold
              size="xl6"
              lineHeight="72px"
              marginBottom="xxs"
            >
              {t('NotFoundScreen.Subtitle')}
            </Typography>
            <Typography
              size="lg"
              lineHeight="24px"
            >
              {t('NotFoundScreen.Description')}
            </Typography>
          </Flex>
          <Button
            bgColor="green"
            width="144px"
            height="36px"
            onClick={handleGoHome}
          >
            {t('NotFoundScreen.Button')}
          </Button>
        </Flex>
      </Flex>
      {isDesktop && (
        <Flex column width="50%">
          <img
            src={notFound}
            alt={t('NotFoundScreen.Title')}
            height="100%"
          />
        </Flex>
      )}
    </Flex>
  );
};

export default NotFoundScreen;
