import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { getUserLanguage } from '@utils/storage';

import commonEn from './translations/en.json';
import commonNo from './translations/no.json';

const resources = {
  en: {
    translation: commonEn,
  },
  no: {
    translation: commonNo,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: getUserLanguage() || 'no',
    interpolation: {
      escapeValue: true,
    },
  });

export default i18n;
