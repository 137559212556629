export const setToken = token => localStorage.setItem('token', token);
export const getToken = () => localStorage.getItem('token');
export const clearToken = () => localStorage.removeItem('token');

export const setEmail = email => localStorage.setItem('email', email);
export const getEmail = () => localStorage.getItem('email');
export const clearEmail = () => localStorage.removeItem('email');

export const setUserLanguage = language => localStorage.setItem('lng', language);
export const getUserLanguage = () => localStorage.getItem('lng');

export const addChangeListener = callback => window.addEventListener('storage', callback);
export const removeChangeListener = callback => window.removeEventListener('storage', callback);

export const getSubscriptionId = () => localStorage.getItem('subscriptionId');
export const setSubscriptionId = id => localStorage.setItem('subscriptionId', id);
export const clearSubscriptionId = () => localStorage.removeItem('subscriptionId');

export const setUtmParameters = paramsObject => localStorage.setItem('utm_parameters', JSON.stringify(paramsObject));
export const getUtmParameters = () => localStorage.getItem('utm_parameters');
export const clearUtmParameters = () => localStorage.removeItem('utm_parameters');

export const getIsCheckoutSessionActivated = () => localStorage.getItem('getIsCheckoutSessionActivated');
export const setIsCheckoutSessionActivated = data => localStorage.setItem('getIsCheckoutSessionActivated', data);
export const clearIsCheckoutSessionActivated = () => localStorage.removeItem('getIsCheckoutSessionActivated');

export const clearLocalStorage = () => localStorage.clear();
