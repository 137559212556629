/* eslint-disable no-fallthrough */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';
import { useSelector } from 'react-redux';

import { Checkbox, Flex, SearchBar } from '@components';
import { STORE_NAMES } from '@constants';
import { PRODUCT_TYPES } from '@constants/common';
import { useDebounse } from '@hooks';
import styled from 'styled-components';

const Filters = ({ setProducts }) => {
  const { t } = useTranslation();

  const {
    availableProducts: { non_consumable_products, consumable_products },
  } = useSelector(store => store[STORE_NAMES.PRODUCTS]);

  const [checkedBooklets, setCheckedBooklets] = useState(false);
  const [checkedEbooks, setCheckedEbooks] = useState(false);
  const [checkedDietPlans, setCheckedDietPlans] = useState(false);
  const [checkedTrainingPrograms, setCheckedTrainingPrograms] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedFilters, setselectedFilters] = useState([]);

  const debouncedSearchValue = useDebounse(searchValue, 500);

  useEffect(() => {
    const searchedProducts = [...consumable_products, ...non_consumable_products].filter(
      ({ name }) => name.toLowerCase().includes(searchValue.toLowerCase()),
    );

    const filteredProducts = searchedProducts.filter(
      ({ type }) => (selectedFilters.length === 0 ? true : selectedFilters.includes(type)),
    );
    setProducts(filteredProducts);
  }, [selectedFilters, debouncedSearchValue]);

  const handleCheckBooklets = () => {
    setCheckedBooklets(prev => !prev);
    setselectedFilters(prev => {
      if (prev.includes(PRODUCT_TYPES.booklet)) {
        return prev.filter(item => item !== PRODUCT_TYPES.booklet);
      }
      const selectedFiltersCopy = [...prev];
      selectedFiltersCopy.push(PRODUCT_TYPES.booklet);

      return selectedFiltersCopy;
    });
  };

  const handleCheckEbooks = () => {
    setCheckedEbooks(prev => !prev);
    setselectedFilters(prev => {
      if (prev.includes(PRODUCT_TYPES.document)) {
        return prev.filter(item => item !== PRODUCT_TYPES.document);
      }
      const selectedFiltersCopy = [...prev];
      selectedFiltersCopy.push(PRODUCT_TYPES.document);

      return selectedFiltersCopy;
    });
  };

  const handleCheckDietPlans = () => {
    setCheckedDietPlans(prev => !prev);
    setselectedFilters(prev => {
      if (prev.includes(PRODUCT_TYPES.dietPlan)) {
        return prev.filter(item => item !== PRODUCT_TYPES.dietPlan);
      }
      const selectedFiltersCopy = [...prev];
      selectedFiltersCopy.push(PRODUCT_TYPES.dietPlan);

      return selectedFiltersCopy;
    });
  };

  const handleCheckTrainingPrograms = () => {
    setCheckedTrainingPrograms(prev => !prev);
    setselectedFilters(prev => {
      if (prev.includes(PRODUCT_TYPES.trainingProgram)) {
        return prev.filter(item => item !== PRODUCT_TYPES.trainingProgram);
      }
      const selectedFiltersCopy = [...prev];
      selectedFiltersCopy.push(PRODUCT_TYPES.trainingProgram);

      return selectedFiltersCopy;
    });
  };

  const handleChangeSearchValue = e => {
    const { value } = e.target;
    setSearchValue(value);
  };

  return (
    <Wrapper justifyBetween paddingTop="xl4" paddingBottom="xl">
      <SearchBar value={searchValue} onChange={handleChangeSearchValue} />
      <CheckboxesWrapper grow="0">
        <Checkbox
          value={checkedBooklets}
          label={t('Products.Booklets')}
          onChange={handleCheckBooklets}
          checkboxId="booklets"
        />
        <Checkbox
          value={checkedEbooks}
          label={t('Products.E-books')}
          onChange={handleCheckEbooks}
          checkboxId="e-books"
        />
        <Checkbox
          value={checkedDietPlans}
          label={t('Products.DietPlans')}
          onChange={handleCheckDietPlans}
          checkboxId="diet-plans"
        />
        <Checkbox
          value={checkedTrainingPrograms}
          label={t('Products.TrainingPrograms')}
          onChange={handleCheckTrainingPrograms}
          checkboxId="training-programs"
        />
      </CheckboxesWrapper>
    </Wrapper>
  );
};

Filters.propTypes = {
  setProducts: func,
};

Filters.defaultProps = {
  setProducts: () => {},
};

const Wrapper = styled(Flex)`
  flex-direction: column;
  max-width: 547px;
  @media (max-width: 1024px) {
    max-width: unset;
  }
`;

const CheckboxesWrapper = styled(Flex)`
  margin-top: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 1024px) {
    margin-bottom: 24px;
    gap: 8px;
  }

  @media (max-width: 500px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export default Filters;
