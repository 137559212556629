import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  arrayOf, number, shape, string,
} from 'prop-types';

import { routes } from '@utils';
import { Flex, Links, Typography } from '@components';
import { PRODUCT_TYPES } from '@constants/common';

const ProductsList = ({ products }) => {
  const { t } = useTranslation();

  const handleProductType = type => {
    switch (type) {
      case PRODUCT_TYPES.booklet:
        return t('Products.Booklet');
      case PRODUCT_TYPES.document:
        return t('Products.E-book');
      case PRODUCT_TYPES.trainingProgram:
        return t('Products.TrainingProgram');
      case PRODUCT_TYPES.dietPlan:
        return t('Products.DietPlan');
      default:
        return 'Product';
    }
  };

  return (
    <Flex column shadow="light" borderRadius="sm">
      {products.length ? (
        products.map(product => (
          <BorderedWrapper key={product.id} itemsCenter justifyBetween padding="xl4">
            <Flex column grow="0" width="75%">
              <Typography size="md" color="greyApple">{handleProductType(product.type)}</Typography>
              <Typography size="lg" color="darkSecondary">{product.name}</Typography>
            </Flex>
            <Flex grow="0" width="25%">
              <Links.Text
                // eslint-disable-next-line max-len
                to={`${routes.products}/${product.type !== PRODUCT_TYPES.booklet && product.type !== PRODUCT_TYPES.document ? 'consumable' : 'non-consumable'}/${product.id}`}
                color="green"
                size="md"
              >
                {`${t('Products.BuyFor')} ${product.price / 100} NOK`}
              </Links.Text>
            </Flex>
          </BorderedWrapper>
        ))
      ) : (
        <BorderedWrapper padding="xl4" justifyCenter>
          <Typography color="greyApple">{t('Products.Placeholder')}</Typography>
        </BorderedWrapper>
      )}
    </Flex>
  );
};

const BorderedWrapper = styled(Flex)`
    &:not(:last-child) {
        border-bottom: 1px solid rgba(107, 120, 136, 0.1);
    }
`;

ProductsList.propTypes = {
  products: arrayOf(shape({
    id: number,
    name: string,
    price: number,
    type: string,
  })).isRequired,
};

export default ProductsList;
