import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  bool, func, node,
} from 'prop-types';
import styled, { css } from 'styled-components';
import { useOutsideClickHandler } from '@hooks';

const MODAL_ROOT_WRAPPER_ID = 'modal-root';

const Modal = ({
  isOpen, onClose, children,
}) => {
  const [wrapper, setWrapper] = useState(null);
  const ref = useOutsideClickHandler(onClose);

  useEffect(() => {
    if (!isOpen) {
      wrapper?.remove();
      return;
    }

    let element = document.getElementById(MODAL_ROOT_WRAPPER_ID);

    if (!element) {
      element = document.createElement('div');

      element.setAttribute('id', MODAL_ROOT_WRAPPER_ID);
      document.body.appendChild(element);
    }

    setWrapper(element);
    document.body.style.overflow = 'hidden';
  }, [isOpen]);

  useEffect(() => () => {
    const root = document.getElementById(MODAL_ROOT_WRAPPER_ID);

    if (root) {
      root.remove();
      document.body.style.overflow = 'unset';
    }
  }, []);

  if (!wrapper) return null;

  return createPortal(
    <ModalContainer isOpen={isOpen} ref={ref}>
      {children}
    </ModalContainer>,
    wrapper,
  );
};

Modal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  children: node.isRequired,
};

const ModalContainer = styled.div`
  ${({ theme: { boxShadow }, isOpen }) => css`
   box-shadow: ${boxShadow.default};
    background-color: #fff;
    margin: auto;
    opacity: 0;
    border-radius: 16px;
    pointer-events: none;
    transform: scale(0.4);
    ${isOpen && css`
        opacity: 1;
        pointer-events: auto;
        transform: scale(1);
    `}
  `}
`;

export default Modal;
