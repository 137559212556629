import React from 'react';
import { bool, number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Flex from '../Flex';

import { formatSubscriptionPrice } from '../../utils/helpers';
import Typography from '../Typography';

const PromocodeAppliedLabel = ({
  freeDaysAmount, discount, newSubscriptionPrice, isApplied,
}) => {
  const { t } = useTranslation();

  if (!isApplied || (!freeDaysAmount && !discount)) return null;

  return (
    <Flex column flex={{ grow: 0 }} height="56px">
      {
        !!freeDaysAmount && (
          <>
            <Typography fadinAnimation color="greyApple" size="lg">
              {t('GetSubscription.Promocode.YouWillGet')}
            </Typography>
            <Typography fadinAnimation color="green" weight="600" size="xl2">
              {t('GetSubscription.Promocode.DaysFreeTrial.keyWithCount', { count: freeDaysAmount })}
            </Typography>
          </>
        )
      }
      {
        !!discount && (
          <Typography fadinAnimation color="greyApple">
            {`${t('GetSubscription.Promocode.NewPrice')} - `}
            <Typography display="inline" color="darkSecondary" weight="bold">
              {formatSubscriptionPrice(newSubscriptionPrice)}
            </Typography>
          </Typography>
        )
      }
    </Flex>
  );
};

PromocodeAppliedLabel.propTypes = {
  freeDaysAmount: number,
  discount: number,
  newSubscriptionPrice: number,
  isApplied: bool.isRequired,
};

PromocodeAppliedLabel.defaultProps = {
  freeDaysAmount: 0,
  discount: 0,
  newSubscriptionPrice: 0,
};

export default PromocodeAppliedLabel;
