/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Flex, Header, HeaderMobile, TabsContainer,
} from '@components';
import { useWindowSize } from '@hooks';
import { STORE_NAMES } from '@constants';
import { TRACKING_PRODUCT_TYPES } from '@constants/common';
import { clearGtmDataLayer } from '@utils/helpers';

import { useLocation } from 'react-router-dom';
import { ProductsTab, SubscriptionsTabDesktop, SubscriptionsTabMobile } from './tabs';
import { getAllSubscriptions } from '../../store/products/duck';

const Subscriptions = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [, isDesktop] = useWindowSize();

  const location = useLocation();

  const activeTabId = location.state?.activeTabId;

  const { availableProducts: { has_active_subscription } } = useSelector(store => store[STORE_NAMES.PRODUCTS]);
  const { authorization_info } = useSelector(store => store[STORE_NAMES.AUTH]);

  const trackViewItemList = ({
    products, customerId, type,
  }) => {
    clearGtmDataLayer();

    window.dataLayer.push({
      event: 'view_item_list',
      eventProps: {
        item_list_name: `all ${type}s`,
        item_list_id: type === TRACKING_PRODUCT_TYPES.subscription
          ? 1
          : 2,
        items: products.map(product => ({
          item_id: product.id,
          item_name: product.title ?? product.name,
          item_category: product.type ?? 'subscription',
        })),
      },
      userProps: {
        user_id: customerId,
      },
      meta: {
        email: authorization_info.email,
        eventProps: {
          content_ids: products.map(product => product.id),
          content_category: 'fitness',
          content_name: `${type}s list`,
          content_type: type,
          contents: products.map(product => ({
            id: product.id,
            quantity: 1,
          })),
        },
      },
    });
  };

  const trackList = ({ products, customerId, type }) => {
    trackViewItemList({
      products, customerId, type,
    });
  };

  useEffect(() => {
    dispatch(getAllSubscriptions());
  }, []);

  const tabs = useMemo(() => ([
    {
      title: t('Subscriptions.SubscriptionsTab'),
      id: 1,
      component: isDesktop
        ? <SubscriptionsTabDesktop trackList={trackList} />
        : <SubscriptionsTabMobile trackList={trackList} />,
    },
    {
      title: t('Subscriptions.ProductsTab'),
      id: 2,
      component: <ProductsTab trackList={trackList} />,
      disabled: !has_active_subscription,
      tooltipText: t('Products.Tooltip'),
    },
  ]), [isDesktop, i18n.language, has_active_subscription]);

  return (
    <Flex column minHeight="100vh">
      {isDesktop ? <Header /> : <HeaderMobile />}
      <Flex
        grow="0"
        marginLeft="xl10"
        marginRight="xl10"
        justifyCenter
      >
        {
          authorization_info?.token
            ? (
              <TabsContainer tabs={tabs} activeTabId={activeTabId} />
            ) : tabs[0].component
        }
      </Flex>
    </Flex>
  );
};

export default Subscriptions;
