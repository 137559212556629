import { useTranslation } from 'react-i18next';
import React from 'react';
import { bool, func } from 'prop-types';
import { useDispatch } from 'react-redux';
import { deleteAccount } from '@store';
import { routes } from '@utils';
import { useHistory } from 'react-router-dom';
import { showErrorToast, showSuccessToast } from '../../utils/helpers';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

const DeleteAccountModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleAccountDeletion = async () => {
    const result = await dispatch(deleteAccount());

    if (result.error) {
      showErrorToast(result.payload.data.error.message);
      return;
    }

    showSuccessToast(t('DeleteAccount.SuccessToast'));
    history.push(routes.logIn);
  };

  return (
    <ConfirmationModal
      title={`${t('DeleteAccount.Title')}?`}
      subtitle={t('DeleteAccount.Subtitle')}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleAccountDeletion}
      confirmButtonText={t('General.Delete')}
      cancelButtonText={t('General.Cancel')}
      cancelButtonProps={{
        color: 'blueDark',
      }}
      confirmButtonProps={{
        color: 'redDark',
      }}
    />
  );
};

DeleteAccountModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default DeleteAccountModal;
