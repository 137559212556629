import styled, { css } from 'styled-components';
import Typography from '../Typography';
import Flex from '../Flex';

export const Label = styled(Typography)`
  ${({ theme: { color }, isMobile, isActive }) => css`
    font-size: 16px;
    color: ${color.darkSecondary};
    
    ${isMobile && css`
        font-size: 14px;
    `}
    
    ${isActive && css`
        color: ${color.white};
    `}
    
  `}
`;

export const Container = styled(Flex)`
  ${({
    theme: { color },
    isMobile,
    isActive,
    onClick,
  }) => css`
    align-items: center;
    justify-content: center;
    padding: 10px 8px;
    width: 100%;
    max-width: 200px;
    height: 46px;
    color: ${color.darkSecondary};
    background-color: rgba(107, 120, 136, 0.08);
    border-radius: 16px;
    white-space: nowrap;

    ${isMobile && css`
      min-width: min-content;
      max-width: 140px;
      height: 40px;
      border-radius: 12px;
    `}

    ${isActive && css`
      background-color: ${color.green};
    `}
    
    cursor: ${onClick ? 'pointer' : 'default'};
  `}
`;
