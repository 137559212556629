import { ANALYTICS_ENDPOINTS, HTTP_METHODS } from '@constants';
import { apiClient } from '@utils';

const {
  TRACK_VIEW_CONTENT,
  TRACK_INITIATE_CHECKOUT,
  TRACK_PURCHASE,
  GET_PURCHASE_GTM_DATA,
  TRACK_LEAD,
} = ANALYTICS_ENDPOINTS;

const { POST, GET } = HTTP_METHODS;

const trackViewContent = data => apiClient.authorizedRequest({
  method: POST,
  url: TRACK_VIEW_CONTENT,
  data,
});

const trackInitiateCheckout = data => apiClient.authorizedRequest({
  method: POST,
  url: TRACK_INITIATE_CHECKOUT,
  data,
});

const trackPurchase = data => apiClient.authorizedRequest({
  method: POST,
  url: TRACK_PURCHASE,
  data,
});

const getPurchaseGTMData = params => apiClient.unauthorizedRequest({
  method: GET,
  url: GET_PURCHASE_GTM_DATA,
  params,
});

const trackLead = data => apiClient.authorizedRequest({
  method: POST,
  url: TRACK_LEAD,
  data,
});

const api = {
  trackViewContent,
  trackLead,
  trackInitiateCheckout,
  trackPurchase,
  getPurchaseGTMData,
};

export default api;
