import DietPlan from '@assets/DietPlan.png';
import Recipes from '@assets/Recipes.png';
import LogFood from '@assets/LogFood.png';
import Coaching from '@assets/Coaching.png';
import TrainingProgram from '@assets/TrainingProgram.png';
import Workout from '@assets/Workout.png';
import PlanMeals from '@assets/PlanMeals.png';

export const allFeatures = [{
  id: 'diet',
  title: 'SubscriptionsInfo.DietPlan.Title',
  description: 'SubscriptionsInfo.DietPlan.Description',
  imageSrc: DietPlan,
  field: 'has_diet_plan',
},
{
  id: 'recipes',
  title: 'SubscriptionsInfo.Recipes.Title',
  description: 'SubscriptionsInfo.Recipes.Description',
  imageSrc: Recipes,
  field: 'has_recipes_db',
},
{
  id: 'logFood',
  title: 'SubscriptionsInfo.LogFood.Title',
  description: 'SubscriptionsInfo.LogFood.Description',
  imageSrc: LogFood,
},
{
  id: 'coaching',
  title: 'SubscriptionsInfo.Coaching.Title',
  description: 'SubscriptionsInfo.Coaching.Description',
  imageSrc: Coaching,
  field: 'has_coaching',
},
{
  id: 'trainingProgram',
  title: 'SubscriptionsInfo.TrainingProgram.Title',
  description: 'SubscriptionsInfo.TrainingProgram.Description',
  imageSrc: TrainingProgram,
  field: 'has_own_training_program',
},
{
  id: 'workout',
  title: 'SubscriptionsInfo.Workout.Title',
  description: 'SubscriptionsInfo.Workout.Description',
  imageSrc: Workout,
},
{
  id: 'planMeals',
  title: 'SubscriptionsInfo.PlanMeals.Title',
  description: 'SubscriptionsInfo.PlanMeals.Description',
  imageSrc: PlanMeals,
},
];

export const getAvailableFeatures = subscription => allFeatures
  .filter(({ field }) => (field ? !!subscription[field] : true));
