import axios from 'axios';
import { storage, routes } from '@utils';

import { common } from '@constants';

const defaultAxiosInstanceOptions = {
  baseURL: common.BASE_URL,
};

const headerInterceptor = async config => {
  const token = storage.getToken();
  const configWithDynamicHeaders = {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token || ''}`,
    },
  };

  return configWithDynamicHeaders;
};

const resolveInterceptor = response => response;

const rejectInterceptor = error => {
  if (error.response?.status === common.UNAUTHORIZED) {
    storage.clearToken();
    window.location.replace(routes.logIn);
  }

  if (error.response?.status === common.NOT_FOUND) {
    window.location.replace(routes.notFound);
  }

  return Promise.reject(error);
};

const unauthorizedApiClient = axios.create(defaultAxiosInstanceOptions);

const authorizedApiClient = axios.create(defaultAxiosInstanceOptions);

authorizedApiClient.interceptors.request.use(headerInterceptor);
authorizedApiClient.interceptors.response.use(resolveInterceptor, rejectInterceptor);

const wrappedAxiosRequest = isAuthorized => async ({
  url,
  method,
  data = null,
  headers = {},
  params = {},
}) => {
  const apiClient = isAuthorized ? authorizedApiClient : unauthorizedApiClient;

  const dynamicHeaders = {
    ...headers,
    'Customer-Date': new Date().toISOString(),
    'Time-Zone': Intl.DateTimeFormat().resolvedOptions(new Date().toISOString()).timeZone,
    Offset: new Date().getTimezoneOffset(),
  };

  const config = {
    url,
    method,
    data,
    params,
    headers: dynamicHeaders,
  };

  try {
    const response = await apiClient.request(config);

    return response?.data ? { response: response.data, error: null } : { response: null, error: null };
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return {
        response: null,
        error: {
          data: error.response.data,
          status: error.response.status,
        },
      };
    } if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return {
        response: null,
        error: {
          data: error.request,
        },
      };
    }
    return {
      response: null,
      error: {
        data: error,
      },
    };
  }
};

const apiClient = {
  authorizedRequest: wrappedAxiosRequest(true),
  unauthorizedRequest: wrappedAxiosRequest(false),
};

export default apiClient;
