import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import jwt_decode from 'jwt-decode';

import {
  Flex, Typography, InputField, Icon, Links, ButtonWithLoader, LanguageSwitcher, Checkbox,
} from '@components';
import { EMAIL_REGEX, PASSWORD_REGEX, STORE_NAMES } from '@constants';
import { PRIVACY_POLICY_LINK, TERMS_AND_CONDITIONS_LINK } from '@constants/common';
import { login, register } from '@store';
import { routes } from '@utils';
import {
  clearToken, setToken, getSubscriptionId, clearSubscriptionId,
} from '@utils/storage';
import { showErrorToast } from '@utils/helpers';
import { useWindowSize } from '@hooks';
import { ReactComponent as ArrowBack } from '@assets/ArrowBack.svg';

import { PreviewImage, SocialButtons } from './components';

const SignUpScreen = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isMobile, isDesktop] = useWindowSize();

  const [subscriptionId] = useState(getSubscriptionId());
  const [isAgreedWithPolicy, setIsAgreedWithPolicy] = useState(false);
  const [isPolicyAgreementError, setIsPolicyAgreementError] = useState(false);

  const { isPending } = useSelector(store => store[STORE_NAMES.AUTH]);

  const handleCheckPolicyAgreement = () => {
    setIsAgreedWithPolicy(prev => !prev);
    setIsPolicyAgreementError(false);
  };

  const validatePolicyAgreement = () => {
    showErrorToast(t('Validation.PolicyAgreement'));
    setIsPolicyAgreementError(true);
  };

  const validate = values => {
    const errors = {};

    if (!PASSWORD_REGEX.test(values.password)) {
      errors.invalidPassword = t('Validation.InvalidPassword');
    }

    if (!EMAIL_REGEX.test(values.email)) {
      errors.invalidEmail = t('Validation.InvalidEmail');
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validate,
    validateOnChange: false,
    onSubmit: async values => {
      if (!isAgreedWithPolicy) {
        validatePolicyAgreement();
        return;
      }

      const result = await dispatch(register({
        email: values.email,
        password: values.password,
        isMobile: false,
      }));

      if (result.error) {
        showErrorToast(result.payload.data.error.message);
      } else {
        history.push({
          pathname: routes.verifyEmail,
          state: { isVerifyEmail: true, signupMethod: 'email' },
        });
      }
    },
  });

  const removeSubscriptionId = () => {
    if (subscriptionId) {
      clearSubscriptionId();
    }
  };

  const handleEmailChange = e => {
    const { value } = e.target;
    formik.setFieldValue('email', value);
  };

  const handlePasswordChange = e => {
    const { value } = e.target;
    formik.setFieldValue('password', value);
  };

  const handleGoBack = () => {
    history.push(routes.root);
  };

  const handleLogin = ({ result, method }) => {
    const { token } = result.payload;

    clearToken();
    removeSubscriptionId();
    setToken(token);

    const historyPath = subscriptionId
      ? `${routes.subscriptions}/${subscriptionId}`
      : routes.subscriptions;

    history.push(historyPath, {
      signupMethod: method,
    });
  };

  const handleFacebookSubmit = async response => {
    if (!isAgreedWithPolicy) {
      validatePolicyAgreement();
      return;
    }

    const result = await dispatch(register({
      authToken: response.accessToken,
      provider: 'facebook',
      fullName: response.name,
      language: 'English',
      isMobile: false,
    }));

    if (result.error) {
      showErrorToast(result.payload.data.error.message);
    } else {
      const loginResult = await dispatch(login({
        authToken: response.accessToken,
        provider: 'facebook',
        language: 'English',
        isMobile: false,
      }));

      if (loginResult.error) {
        showErrorToast(loginResult.payload.data.error.message);
      } else {
        handleLogin({
          result: loginResult,
          method: 'SSO',
        });
      }
    }
  };

  const handleGoogleSubmit = async (response, isPolicyApproveValid) => {
    if (!isPolicyApproveValid) {
      validatePolicyAgreement();
      return;
    }

    const decodedCredential = jwt_decode(response.credential);
    const result = await dispatch(register({
      authToken: response.credential,
      provider: 'google',
      fullName: decodedCredential.name,
      language: 'English',
      isMobile: false,
    }));

    if (result.error) {
      showErrorToast(result.payload.data.error.message);
    } else {
      const loginResult = await dispatch(login({
        authToken: response.credential,
        provider: 'google',
        language: 'English',
        isMobile: false,
      }));

      if (loginResult.error) {
        showErrorToast(loginResult.payload.data.error.message);
      } else {
        handleLogin({
          result: loginResult,
          method: 'SSO',
        });
      }
    }
  };

  const handleAppleSubmit = async response => {
    if (!isAgreedWithPolicy) {
      validatePolicyAgreement();
      return;
    }

    const result = await dispatch(register({
      authToken: response.authorization.id_token,
      provider: 'apple',
      fullName: `${response?.user?.name?.firstName} ${response?.user?.name?.lastName}`,
      language: 'English',
      isMobile: false,
    }));

    if (result.error) {
      showErrorToast(result.payload.data.error.message);
    } else {
      const loginResult = await dispatch(login({
        authToken: response.authorization.id_token,
        provider: 'apple',
        language: 'English',
        isMobile: false,
      }));

      if (loginResult.error) {
        showErrorToast(loginResult.payload.data.error.message);
      } else {
        handleLogin({
          result: loginResult,
          method: 'SSO',
        });
      }
    }
  };

  return (
    <Flex minHeight="100vh">
      {isDesktop && <PreviewImage />}
      <Flex justifyCenter itemsCenter>
        <Flex column maxWidth={isMobile ? '80%' : '60%'}>
          {isDesktop ? (
            <Flex justifyCenter marginBottom="45px">
              <LanguageSwitcher />
            </Flex>
          ) : (
            <Flex cursor="pointer" onClick={handleGoBack} marginTop="xl4">
              <Icon SVG={ArrowBack} />
            </Flex>
          )}
          <Typography
            marginBottom="sm"
            color="dark"
            size="xl4"
            weight="bold"
            textAlign="center"
          >
            {t('SignUpScreen.Title')}
          </Typography>
          <Flex marginBottom="xl6" selfCenter>
            <Typography size="lg" color="darkSecondary" marginRight="xxs">{t('SignUpScreen.Subtitle')}</Typography>
            <Links.Text to={routes.logIn} color="green">{t('SignUpScreen.LogIn')}</Links.Text>
          </Flex>
          <InputField
            name="email"
            label={t('AuthCommonContent.Email')}
            value={formik.values.email}
            onChange={handleEmailChange}
            isError={!!formik.errors.invalidEmail}
          />
          <InputField
            name="password"
            password
            label={t('AuthCommonContent.Password')}
            value={formik.values.password}
            onChange={handlePasswordChange}
            isError={!!formik.errors.invalidPassword}
          />
          <Typography
            color={formik.errors.invalidPassword ? 'sunsetOrange' : 'greySecondary'}
            size="sm"
            lineHeight="14px"
            marginBottom="xl4"
          >
            {t('SignUpScreen.Validation.Password')}
          </Typography>
          <ButtonWithLoader
            onClick={formik.handleSubmit}
            text={t('SignUpScreen.Title')}
            isLoading={isPending}
          />
          <Checkbox
            value={isAgreedWithPolicy}
            onChange={handleCheckPolicyAgreement}
            label={(
              <>
                {t('AuthCommonContent.PolicyAgreementStart')}
                <Links.Text
                  to={{ pathname: TERMS_AND_CONDITIONS_LINK }}
                  target="_blank"
                  color={isPolicyAgreementError ? 'sunsetOrange' : 'greyApple'}
                  size="md"
                  underline
                >
                  {t('AuthCommonContent.TemsAndConditions')}
                </Links.Text>
                {t('AuthCommonContent.PolicyAgreementConjunction')}
                <Links.Text
                  to={{ pathname: PRIVACY_POLICY_LINK }}
                  target="_blank"
                  color={isPolicyAgreementError ? 'sunsetOrange' : 'greyApple'}
                  size="md"
                  underline
                >
                  {t('AuthCommonContent.PrivacyPolicy')}
                </Links.Text>
              </>
            )}
            checkboxId="policy-agreement"
            textColor={isPolicyAgreementError ? 'sunsetOrange' : 'greyApple'}
            textSize="md"
            marginTop="16px"
            gap="5px"
          />
          <Typography
            marginTop="xl"
            marginBottom="xl"
            color="greySecondary"
            size="md"
            textAlign="center"
          >
            {t('AuthCommonContent.ContinueWith')}
          </Typography>
          <Flex selfCenter column marginBottom="xl4" width="100%">
            <SocialButtons
              isAgreedWithPolicy={isAgreedWithPolicy}
              handleFacebookSubmit={handleFacebookSubmit}
              handleGoogleSubmit={handleGoogleSubmit}
              handleAppleSubmit={handleAppleSubmit}
            />
          </Flex>
          {!isDesktop && (
            <Flex justifyCenter marginTop="xl6">
              <LanguageSwitcher />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SignUpScreen;
