import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  bool, func, number, string,
} from 'prop-types';
import { Flex, Typography } from '@components';
import { useWindowSize } from '@hooks';

const TabNavItem = ({
  id, title, activeTab, setActiveTab, disabled, tooltipText,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [isMobile, isDesktop] = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    if (!disabled) {
      setActiveTab(id);
    }
  };

  const handleOpenToggle = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <BorderedWrapper>
      <Flex
        height="40px"
        width={isMobile ? '150px' : '190px'}
        // eslint-disable-next-line no-nested-ternary
        className={disabled ? 'disabled' : id === activeTab ? 'active' : 'inactive'}
        justifyCenter
        cursor="pointer"
        onClick={handleClick}
        onMouseOver={handleOpenToggle}
        onMouseOut={handleOpenToggle}
        position="relative"
      >
        {
          typeof title === 'string' ? (
            <Typography
              weight="bold"
              size="xl"
              nowrap
              color={id === activeTab ? 'green' : 'darkSecondary'}
            >
              {title}
            </Typography>
          ) : title
        }
        {disabled && isOpen && (
          <Wrapper
            bgColor="white"
            shadow="light"
            borderRadius="sm"
            {...(typeof title !== 'string' ? { topPosition: true } : {})}
          >
            <Typography color="darkSecondary" padding="md" className="tooltip">
              {tooltipText}
            </Typography>
          </Wrapper>
        )}
      </Flex>
    </BorderedWrapper>
  );
};

TabNavItem.propTypes = {
  id: number.isRequired,
  title: string.isRequired,
  activeTab: number.isRequired,
  setActiveTab: func.isRequired,
  disabled: bool,
  tooltipText: string,
};

TabNavItem.defaultProps = {
  disabled: false,
  tooltipText: '',
};

const BorderedWrapper = styled(Flex)`
  ${({ theme: { color } }) => css`
    transition: all 0.3s;

    .active {
      border-bottom: 1px solid ${color.green};
    }

    .disabled {
      border-bottom: 1px solid rgba(107, 120, 136, 0.3);
    }

    .disabled > p {
      opacity: 0.2;
    }

    .disabled > svg {
      opacity: 0.2;
    }

    .disabled:hover p {
      color: ${color.darkSecondary};
    }

    .inactive {
      border-bottom: 1px solid ${color.grey};
    }

    .inactive:hover {
      border-bottom-color: ${color.green};
    }

    :hover p {
      color: ${color.green};
    }
  `}
`;

const Wrapper = styled(Flex)`
  position: absolute;
  top: 50%;
  left: 200px;
  z-index: 2;
  transform: translateY(-50%);
  width: 360px;
  
  @media (max-width: 1024px) {
    top: 85px;
    left: -10%;
  }

  @media (max-width: 768px) {
    left: -68%;
  }

  @media (max-width: 480px) {
    top: 90px;
    left: -100%;
    width: 300px;
  }

  ${props => props.topPosition && `
    top: -100%;
    left: 0;
  `}

  .tooltip {
    opacity: 1;
  }
`;

export default TabNavItem;
