import React from 'react';
import { number, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Button, Flex, Typography,
} from '@components';
import { routes } from '@utils';
import { removePrefix, clearGtmDataLayer } from '@utils/helpers';
import { STORE_NAMES } from '@constants';

const SubscriptionCard = ({ subscription }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const { authorization_info } = useSelector(store => store[STORE_NAMES.AUTH]);

  const trackLead = () => {
    clearGtmDataLayer();
    window.dataLayer.push({
      event: 'lead',
      meta: {
        email: authorization_info.email,
        eventProps: {
          content_category: 'fitness',
          content_name: subscription.title,
          currency: 'NOK',
          value: subscription.price / 100,
        },
      },
      userProps: {
        user_id: authorization_info.id,
        subscription_id: subscription.id,
      },
    });
  };

  const handleGetSubscription = () => {
    trackLead();
    history.push(`${routes.subscriptions}/${subscription.id}`);
  };

  // const isSlideLastInRow = index % 3 === 0 && index !== 0;

  return (
    <Flex column width="160px">
      <Typography size="xl2" color="darkSecondary" marginBottom="8px">
        {
          i18n.language === 'en'
            ? removePrefix(subscription.title, 'Secondary')
            : removePrefix(subscription.title_no, 'Secondary')
        }
      </Typography>
      <Flex itemsEnd marginBottom="xl">
        <Typography
          size="xl4"
          color="darkSecondary"
          marginRight="sm"
          weight="bold"
        >
          {subscription.price / 100}
        </Typography>
        {/* <Flex itemsCenter>
          <Typography
            size="xl2"
            color="greyApple"
            marginRight="xxs"
          >
            {subscription.price_after_first_payment / 100}
          </Typography>
          {subscription.price_after_first_payment
          && <Tooltip tooltipText={t('Subscriptions.PriceTooltip')} position={isSlideLastInRow ? 'left' : 'right'} />}
        </Flex> */}
      </Flex>
      <Button height="54px" bgColor="green" onClick={handleGetSubscription}>
        {t('Subscriptions.GetButton')}
      </Button>
    </Flex>
  );
};

SubscriptionCard.propTypes = {
  subscription: shape({
    title: string.isRequired,
    price: number.isRequired,
    price_after_first_payment: number.isRequired,
  }).isRequired,
  // index: number.isRequired,
};

export default SubscriptionCard;
