import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { ButtonWithLoader, Flex } from '@components';
import { useWindowSize } from '@hooks';
import { showErrorToast } from '@utils/helpers';
import { useDispatch } from 'react-redux';
import { createStripeCheckoutSession } from 'src/store/products/duck';
import { number, string } from 'prop-types';
import { setIsCheckoutSessionActivated } from 'src/utils/storage';

const PaymentBlock = ({ productType, productId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [isMobile, isDesktop] = useWindowSize();

  const [isPaymentProcessing, setPaymentProcessing] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();

    setPaymentProcessing(prev => !prev);

    const result = await dispatch(createStripeCheckoutSession({
      type: productType,
      productId,
    }));

    if (result.error) {
      setPaymentProcessing(prev => !prev);
      showErrorToast(result.payload.data.error.message);
      return;
    }

    setIsCheckoutSessionActivated('true');

    const url = result.payload?.sessionUrl;
    window.location.href = url;

    setPaymentProcessing(prev => !prev);
  };

  return (
    <Wrapper
      column
      justifyCenter
      width={isDesktop ? '50%' : '100%'}
      padding="xl6"
      shadow="light"
    >
      <ButtonWithLoader
        onClick={handleSubmit}
        text={t('GetProduct.CheckoutButton')}
        isLoading={isPaymentProcessing}
      />
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  ${({ theme: { color, spacing, border } }) => css`
  .StripeElement {
    width: 100%;
    padding: ${spacing.sm};
    border: 1.5px solid ${color.lightGrey};
    border-radius: ${border.radius.sm};
  }
  `}
`;

PaymentBlock.propTypes = {
  productType: string.isRequired,
  productId: number.isRequired,
};

export default PaymentBlock;
