/* eslint-disable function-paren-newline */
import React, { StrictMode } from 'react';
import { render } from 'react-dom';

import App from './App';

const root = document.getElementById('root');

render(
  <StrictMode>
    <App />
  </StrictMode>, root,
);

