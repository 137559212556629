const AUTH_ENDPOINTS = {
  LOGIN: '/mobile/login',
  REGISTER: '/mobile/register',
  VERIFY_EMAIL: '/mobile/register/verification-code',
  RESET_PASSWORD: '/mobile/reset-password/email',
  RESET_PASSWORD_VERIFY: '/mobile/reset-password/verification-code',
  CHANGE_PASSWORD: '/mobile/reset-password',
  LOGOUT: '/mobile/logout',
  DELETE_ACCOUNT: '/mobile/account',
  CHANGE_EMAIL_VERIFICATION: '/mobile/change-email/verification',
};

const PRODUCTS_EDPOINTS = {
  PRODUCTS: '/mobile/products',
  SUBSCRIPTIONS: '/mobile/subscriptions',
  PURCHASES: '/mobile/purchases',
  CREATE_SUBSCRIPTION: '/mobile/subscription',
  CANCEL_SUBSCRIPTION: '/mobile/subscription/cancel',
  RESTORE_SUBSCRIPTION: '/mobile/subscription/restore',
  CREATE_CHECKOUT_SESSION: '/mobile/checkout-session',
  CHECK_PROMOCODE: '/mobile/promocode/check',
};

const CUSTOMER_ENDPOINTS = {
  CUSTOMER_BY_EMAIL: '/mobile/customer/email',
  CUSTOMER: '/mobile/customer',
};

const ANALYTICS_ENDPOINTS = {
  TRACK_VIEW_CONTENT: '/mobile/events/view-content',
  TRACK_INITIATE_CHECKOUT: '/mobile/events/initiate-checkout',
  TRACK_PURCHASE: '/mobile/events/purchase',
  TRACK_LEAD: '/mobile/events/lead',
  GET_PURCHASE_GTM_DATA: '/mobile/events/purchase/gtm',
};

export {
  AUTH_ENDPOINTS,
  PRODUCTS_EDPOINTS,
  ANALYTICS_ENDPOINTS,
  CUSTOMER_ENDPOINTS,
};
