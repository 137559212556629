import React from 'react';
import styled from 'styled-components';
import {
  arrayOf, bool, func, number, shape, string,
} from 'prop-types';
import Slider from 'react-slick';

import { Flex } from '@components';
import { SLIDES_PER_ROW_LIMIT } from '@constants/common';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ArrowButton, SubscriptionCard } from './components';

const SubscriptionsCarousel = ({ subscriptions, attachedSlider, setSubscriptionsSlider }) => {
  const settings = {
    slidesToShow: subscriptions.length > SLIDES_PER_ROW_LIMIT ? SLIDES_PER_ROW_LIMIT : subscriptions.length,
    slidesToScroll: subscriptions.length > SLIDES_PER_ROW_LIMIT ? SLIDES_PER_ROW_LIMIT : subscriptions.length,
    speed: 1000,
    nextArrow: <ArrowButton type="right" />,
    prevArrow: <ArrowButton type="left" />,
  };

  return (
    <Flex grow="0" paddingLeft="240px" paddingTop="36px" paddingBottom="xl4">
      <StyledSlider
        className="subscriptions__slider"
        asNavFor={attachedSlider}
        ref={slider => setSubscriptionsSlider(slider)}
        {...settings}
      >
        {subscriptions.map((subscription, index) => (
          <SubscriptionCard
            key={subscription.id}
            subscription={subscription}
            index={index}
          />
        ))}
      </StyledSlider>
    </Flex>
  );
};

const StyledSlider = styled(Slider)`
    max-width: 1160px;
    @media (max-width: 1460px) {
      max-width: 1060px;
    }

    @media (max-width: 1360px) {
      max-width: 960px;
    }

    @media (max-width: 1260px) {
      max-width: 860px;
    }

    @media (max-width: 1160px) {
      max-width: 790px;
    }

    .slick-track {
      display: flex;
      align-items: flex-end;
      min-width: 1440px;
    }
`;

SubscriptionsCarousel.propTypes = {
  subscriptions: arrayOf(shape({
    id: number.isRequired,
    price: number.isRequired,
    price_after_first_payment: number.isRequired,
    duration: number.isRequired,
    title: string.isRequired,
    has_chat: bool.isRequired,
    has_diet_plan: bool.isRequired,
    has_training_program: bool.isRequired,
    has_own_training_program: bool.isRequired,
    has_coach: bool.isRequired,
    has_coaching: bool.isRequired,
    has_booklets: bool.isRequired,
    has_recipes_db: bool.isRequired,
  })).isRequired,
  setSubscriptionsSlider: func.isRequired,
  attachedSlider: shape({}),
};

SubscriptionsCarousel.defaultProps = {
  attachedSlider: null,
};

export default SubscriptionsCarousel;
