import styled, { css } from 'styled-components';
import { Flex } from '@components';
import { node } from 'prop-types';

const BackgroundImage = styled(Flex)`
    ${({ imageSrc }) => css`
        background-image: url(${imageSrc});
        background-size: cover;
        background-position: center;
        position: relative;
    `}
`;

BackgroundImage.propTypes = {
  children: node.isRequired,
};

export default BackgroundImage;
