import AsyncStorage from '@react-native-async-storage/async-storage';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';

import { STORE_NAMES } from '@constants';

import { authorizationReducer, authorizationPersistConfig } from './authorization/duck';
import { productsPersistConfig, productsReducer } from './products/duck';
import { customerPersistConfig, customerReducer } from './customer/duck';

const persistedReducersObject = {
  authorization: persistReducer(authorizationPersistConfig, authorizationReducer),
  products: persistReducer(productsPersistConfig, productsReducer),
  customer: persistReducer(customerPersistConfig, customerReducer),
};

const rootReducer = combineReducers(persistedReducersObject);

const rootPersistConfig = {
  key: STORE_NAMES.ROOT,
  storage: AsyncStorage,

  blacklist: Object.keys(persistedReducersObject),
};

const persistedRootReducer = persistReducer(rootPersistConfig, rootReducer);

const store = configureStore({
  reducer: persistedRootReducer,
  devTools: true,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

const persistor = persistStore(store);

export { store, persistor };
