import React from 'react';
import {
  arrayOf, func, shape,
} from 'prop-types';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import styled from 'styled-components';

import {
  Flex, Icon, Tooltip, Typography,
} from '@components';
import { ReactComponent as CheckMarkGreen } from '@assets/CheckMarkGreen.svg';
import { ReactComponent as CrossMarkGrey } from '@assets/CrossMarkGrey.svg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SubscriptionsTable = ({ subscriptions, attachedSlider, setTableSlider }) => {
  const { t } = useTranslation();

  const tableHeaders = [
    {
      id: 1,
      title: t('Subscriptions.TableHeaders.Coaching'),
      tooltip: t('Subscriptions.TableTooltips.Coaching'),
    },
    {
      id: 3,
      title: t('Subscriptions.TableHeaders.TrainingProgram'),
      tooltip: t('Subscriptions.TableTooltips.TrainingProgram'),
    },
    {
      id: 4,
      title: t('Subscriptions.TableHeaders.Workout'),
      tooltip: t('Subscriptions.TableTooltips.Workout'),
    },
    {
      id: 5,
      title: t('Subscriptions.TableHeaders.DietPlan'),
      tooltip: t('Subscriptions.TableTooltips.DietPlan'),
    },
    {
      id: 6,
      title: t('Subscriptions.TableHeaders.Recipes'),
      tooltip: t('Subscriptions.TableTooltips.Recipes'),
    },
    // {
    //   id: 7,
    //   title: t('Subscriptions.TableHeaders.Booklets'),
    //   tooltip: t('Subscriptions.TableTooltips.Booklets'),
    // },
  ];

  const settings = {
    slidesToShow: subscriptions.length > 4 ? 4 : subscriptions.length,
    slidesToScroll: subscriptions.length > 4 ? 4 : subscriptions.length,
    speed: 1000,
    arrows: false,
    swipeToSlide: true,
  };

  return (
    <Flex>
      <Flex column maxWidth="240px" className="borderedTop">
        {tableHeaders.map(header => (
          <BorderedFlex
            key={header.id}
            className="borderedTop"
            itemsCenter
          >
            <Typography
              weight="bold"
              color="darkSecondary"
              lineHeight="21px"
              paddingTop="xl"
              paddingBottom="xl"
              marginRight="xs"
            >
              {header.title}
            </Typography>
            {header.tooltip && <Tooltip tooltipText={header.tooltip} width="330px" />}
          </BorderedFlex>
        ))}
      </Flex>
      <StyledSlider
        className="subscriptions__slider subscriptions__slider--table"
        asNavFor={attachedSlider}
        ref={slider => setTableSlider(slider)}
        {...settings}
      >
        {subscriptions.map(({
          id,
          duration,
          has_coaching,
          has_training_program,
          has_own_training_program,
          has_recipes_db,
          has_diet_plan,
        }) => (
          <Flex key={id}>
            <Flex column>
              <Flex
                className="borderedTop"
                paddingTop="xl"
                paddingBottom="xl"
              >
                {has_coaching ? (
                  <Typography size="md">
                    {`${duration} ${t(`Subscriptions.${duration > 1 ? 'Months' : 'Month'}`)}`}
                  </Typography>
                ) : (
                  <Typography size="md">
                    {`${t('Subscriptions.NoCoaching')}`}
                  </Typography>
                )}
              </Flex>
              <Flex
                className="borderedTop"
                paddingTop="xl"
                paddingBottom="xl"
              >
                <Icon SVG={has_training_program ? CheckMarkGreen : CrossMarkGrey} width="20px" height="21px" />
              </Flex>
              <Flex
                className="borderedTop"
                paddingTop="xl"
                paddingBottom="xl"
              >
                <Icon SVG={has_own_training_program ? CheckMarkGreen : CrossMarkGrey} width="20px" height="21px" />
              </Flex>
              <Flex
                className="borderedTop"
                paddingTop="xl"
                paddingBottom="xl"
              >
                <Icon SVG={has_diet_plan ? CheckMarkGreen : CrossMarkGrey} width="20px" height="21px" />
              </Flex>
              <Flex
                className="borderedTop"
                paddingTop="xl"
                paddingBottom="xl"
              >
                <Icon SVG={has_recipes_db ? CheckMarkGreen : CrossMarkGrey} width="20px" height="21px" />
              </Flex>
              {/* <Flex
                className="borderedTop"
                paddingTop="xl"
                paddingBottom="xl"
              >
                <Icon SVG={has_booklets ? CheckMarkGreen : CrossMarkGrey} width="20px" height="21px" />
              </Flex> */}
            </Flex>
          </Flex>
        ))}
      </StyledSlider>
    </Flex>
  );
};

const BorderedFlex = styled(Flex)`
  border-top: 1px solid rgba(107, 120, 136, 0.2);
`;

const StyledSlider = styled(Slider)`
    max-width: 1160px;
    @media (max-width: 1460px) {
      max-width: 1060px;
    }

    @media (max-width: 1360px) {
      max-width: 960px;
    }

    @media (max-width: 1260px) {
      max-width: 860px;
    }

    @media (max-width: 1160px) {
      max-width: 790px;
    }

    .borderedTop {
      border-top: 1px solid rgba(107, 120, 136, 0.2);
    }

    .slick-track {
      display: flex;
      align-items: flex-end;
      min-width: 1440px;
    }
`;

SubscriptionsTable.propTypes = {
  subscriptions: arrayOf(shape({})).isRequired,
  setTableSlider: func.isRequired,
  attachedSlider: shape({}),
};

SubscriptionsTable.defaultProps = {
  attachedSlider: null,
};

export default SubscriptionsTable;
