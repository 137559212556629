import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  Flex, Icon, Typography, Links, MenuDropdown, LogInButton,
} from '@components';
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from '@constants/common';
import { routes } from '@utils';

import { ReactComponent as Logo } from '@assets/Logo.svg';
import { ReactComponent as AppleIconGrey } from '@assets/AppleIconGrey.svg';
import GooglePlay from '@assets/GooglePlay.png';
import { useSelector } from 'react-redux';
import { STORE_NAMES } from '@constants';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

const Header = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { authorization_info } = useSelector(store => store[STORE_NAMES.AUTH]);

  const handleGoHomePage = () => {
    history.push(routes.subscriptions);
  };

  const isAuthorized = !!authorization_info.token;

  const currentURL = history.location.pathname.toLowerCase();

  return (
    <ShadowWrapper
      height="72px"
      maxHeight="72px"
      paddingLeft="xl10"
      paddingRight="xl10"
      justifyCenter
    >
      <Flex
        itemsCenter
        justifyBetween
        maxWidth="1440px"
      >
        <Flex grow="0" itemsCenter>
          <Flex onClick={handleGoHomePage} marginRight="xl4" cursor="pointer">
            <Icon SVG={Logo} width="150px" height="40px" />
          </Flex>
          <Links.Text
            to={{ pathname: APP_STORE_LINK }}
            target="_blank"
            marginRight="xl4"
          >
            <Flex maxWidth="140px" itemsCenter>
              <Icon SVG={AppleIconGrey} width="16px" height="16px" />
              <Typography marginLeft="sm" color="greyApple" fontSize="lg">{t('Subscriptions.AppStoreLink')}</Typography>
            </Flex>
          </Links.Text>
          <Links.Text
            to={{ pathname: GOOGLE_PLAY_LINK }}
            target="_blank"
          >
            <Flex maxWidth="140px" itemsCenter>
              <GooglePlayIcon alt="google play" src={GooglePlay} />
              <Typography
                marginLeft="sm"
                color="greyApple"
                fontSize="lg"
              >
                {t('Subscriptions.GooglePlayLink')}

              </Typography>
            </Flex>
          </Links.Text>
        </Flex>
        {
          isAuthorized && (
            <Flex grow="0">
              <HoverLink
                to={routes.subscriptions}
                color={currentURL.includes(routes.subscriptions) ? 'green' : 'darkSecondary'}
                marginRight="56px"
              >
                {t('Subscriptions.AllProducts')}
              </HoverLink>
              <HoverLink
                to={routes.purchases}
                color={currentURL.includes(routes.purchases) ? 'green' : 'darkSecondary'}
              >
                {t('Subscriptions.MyPurchases')}

              </HoverLink>
            </Flex>
          )
        }
        {
          isAuthorized ? (
            <MenuDropdown />
          ) : (
            <Flex itemsCenter gap="24px" grow="0">
              <LanguageSwitcher variant="short" />
              <LogInButton />
            </Flex>
          )
        }
      </Flex>
    </ShadowWrapper>
  );
};

const ShadowWrapper = styled(Flex)`
  ${({ theme: { boxShadow } }) => css`
   box-shadow: ${boxShadow.default};
  `}
`;

const HoverLink = styled(Links.Text)`
  ${({ theme: { color } }) => css`
    transition: color 0.3s;
    :hover {
      color: ${color.green};
    }
  `}
`;

const GooglePlayIcon = styled.img`
  width: 16px;
  height: 16px;
`;

export default Header;
