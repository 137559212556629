import React from 'react';
import { bool, node, string } from 'prop-types';
import { Route } from 'react-router-dom';

const UnauthorizedRoute = ({ exact, path, children }) => (
  <Route exact={exact} path={path}>
    {children}
  </Route>
);

UnauthorizedRoute.propTypes = {
  exact: bool,
  path: string.isRequired,
  children: node.isRequired,
};

UnauthorizedRoute.defaultProps = {
  exact: false,
};

export default UnauthorizedRoute;
