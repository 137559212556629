import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  Flex, Icon, Typography, Links, LanguageSwitcher, DeleteAccountModal,
} from '@components';
import { logout } from '@store';
import { routes } from '@utils';
import { clearLocalStorage } from '@utils/storage';
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from '@constants/common';

import { ReactComponent as BurgerIcon } from '@assets/BurgerMenuIcon.svg';
import { ReactComponent as AppleIconGrey } from '@assets/AppleIconGrey.svg';
import GooglePlay from '@assets/GooglePlay.png';

const BurgerMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false);

  const handleOpenToggle = () => {
    setIsMenuOpen(prev => !prev);
  };

  const handleLogout = async event => {
    event.preventDefault();

    await dispatch(logout());
    clearLocalStorage();
    history.push(routes.logIn);
  };

  const handleDeleteAccountModalClose = () => {
    setIsDeleteAccountModalOpen(false);
  };

  const handleDeleteAccountModalOpen = event => {
    event.preventDefault();

    setIsDeleteAccountModalOpen(true);
    setIsMenuOpen(false);
  };

  return (
    <Flex grow="0">
      <DeleteAccountModal
        isOpen={isDeleteAccountModalOpen}
        onClose={handleDeleteAccountModalClose}
      />
      <Flex onClick={handleOpenToggle} cursor="pointer" height="27px">
        <Icon SVG={BurgerIcon} />
      </Flex>
      {isMenuOpen && (
        <Flex
          position="absolute"
          top="71px"
          right="0"
          left="0"
          zIndex="2"
          justifyEnd
          paddingRight="xl2"
          paddingBottom="xl4"
          bgColor="white"
          shadow="default"
        >
          <Flex maxWidth="600px" rowReverse margin="0 auto">
            <Flex
              grow="0"
              column
              itemsEnd
            >
              <HoverLink
                to={routes.subscriptions}
                height="48px"
                size="xl"
                color="darkSecondary"
              >
                {t('Subscriptions.AllProducts')}
              </HoverLink>
              <HoverLink
                to={routes.purchases}
                height="48px"
                size="xl"
                color="darkSecondary"
              >
                {t('Subscriptions.MyPurchases')}
              </HoverLink>
              <HoverLink
                size="xl"
                height="48px"
                color="darkSecondary"
                cursor="pointer"
                onClick={handleLogout}
              >
                {t('Subscriptions.Logout')}
              </HoverLink>
              <HoverLink
                size="xl"
                height="48px"
                color="darkSecondary"
                cursor="pointer"
                onClick={handleDeleteAccountModalOpen}
                marginBottom="xl3"
              >
                {t('DeleteAccount.Title')}
              </HoverLink>
              <LanguageSwitcher />
              <Links.Button
                to={{ pathname: APP_STORE_LINK }}
                target="_blank"
                borderColor="platinum"
                paddingRight="xl"
                paddingLeft="xl"
                marginTop="xl2"
              >
                <Icon SVG={AppleIconGrey} width="20px" height="20px" />
                <Typography
                  marginLeft="sm"
                  color="greyApple"
                  size="md"
                >
                  {t('Subscriptions.AppStoreLink')}
                </Typography>
              </Links.Button>
              <Links.Button
                to={{ pathname: GOOGLE_PLAY_LINK }}
                target="_blank"
                borderColor="platinum"
                paddingRight="xl"
                paddingLeft="xl"
                marginTop="xl2"
              >
                <GooglePlayIcon alt="google play" src={GooglePlay} />
                <Typography
                  marginLeft="sm"
                  color="greyApple"
                  size="md"
                >
                  {t('Subscriptions.GooglePlayLink')}
                </Typography>
              </Links.Button>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

const HoverLink = styled(Links.Text)`
  ${({ theme: { color } }) => css`
    transition: color 0.3s;
    :hover {
      color: ${color.green};
    }
  `}
`;

const GooglePlayIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export default BurgerMenu;
