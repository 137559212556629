import React from 'react';
import { func, string } from 'prop-types';
import styled, { css } from 'styled-components';

import { Flex, Icon } from '@components';
import { ReactComponent as ArrowBack } from '@assets/ArrowBack.svg';
import { ReactComponent as ArrowRight } from '@assets/ArrowRight.svg';

const ArrowButton = ({ onClick, type }) => (
  <BorderedFlex
    onClick={onClick}
    width="56px"
    height="48px"
    position="absolute"
    left={type === 'left' ? '-190px' : '-122px'}
    top="15%"
    justifyCenter
    itemsCenter
  >
    <Icon SVG={type === 'left' ? ArrowBack : ArrowRight} />
  </BorderedFlex>
);

const BorderedFlex = styled(Flex)`
  ${({ theme: { color, border: { radius } } }) => css`
    border: 1px solid ${color.lightGrey};
    border-radius: ${radius.default};
    transform: translateY(50%);
  `}
`;

ArrowButton.propTypes = {
  onClick: func,
  type: string.isRequired,
};

ArrowButton.defaultProps = {
  onClick: () => {},
};

export default ArrowButton;
