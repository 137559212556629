import { Button, Modal, Typography } from '@components';
import React from 'react';
import styled, { css } from 'styled-components';
import {
  bool, func, shape, string,
} from 'prop-types';
import Flex from '../Flex';

const ConfirmationModal = ({
  isOpen, onClose, cancelButtonText, confirmButtonText,
  onConfirm, onCancel, title, subtitle, cancelButtonProps,
  confirmButtonProps,
}) => {
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
      return;
    }

    onClose();
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <Container>
        <Typography color="darkSecondary" weight="medium" size="xl" lineHeight="22px" marginBottom="sm">
          {title}
        </Typography>
        <Typography color="darkSecondary" weight="regular" size="md" lineHeight="18px" marginBottom="xl3">
          {subtitle}
        </Typography>
        <Flex justifyBetween>
          <Button
            type="text"
            onClick={handleCancel}
            bgColor="transparent"
            marginLeft="20px"
            {...cancelButtonProps}
          >
            {cancelButtonText}
          </Button>
          <Button
            type="text"
            onClick={onConfirm}
            bgColor="transparent"
            marginRight="20px"
            {...confirmButtonProps}
          >
            {confirmButtonText}
          </Button>
        </Flex>
      </Container>

    </Modal>
  );
};

export default ConfirmationModal;

ConfirmationModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onCancel: func,
  onConfirm: func.isRequired,
  cancelButtonText: string,
  confirmButtonText: string,
  title: string,
  subtitle: string,
  cancelButtonProps: shape({}),
  confirmButtonProps: shape({}),
};

ConfirmationModal.defaultProps = {
  title: '',
  subtitle: '',
  cancelButtonProps: {},
  confirmButtonProps: {},
  onCancel: null,
  cancelButtonText: '',
  confirmButtonText: '',
};

const Container = styled(Flex)`
  ${() => css`
    width: 270px;
    padding: 20px 20px 11px;
    flex-direction: column;
    text-align: center;
  `}
`;

