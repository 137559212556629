import React from 'react';
import { useHistory } from 'react-router-dom';

import { Flex, Icon, BurgerMenu } from '@components';
import { routes } from '@utils';

import { ReactComponent as Logo } from '@assets/Logo.svg';

const HeaderMobile = () => {
  const history = useHistory();

  const handleGoHomePage = () => {
    history.push(routes.subscriptions);
  };
  return (
    <Flex
      position="relative"
      height="72px"
      maxHeight="72px"
      paddingLeft="xl4"
      paddingRight="xl4"
      justifyCenter
    >
      <Flex
        maxWidth="600px"
        margin="0 auto"
        itemsCenter
        justifyBetween
      >
        <Flex
          grow="0"
          onClick={handleGoHomePage}
          marginRight="xl4"
          cursor="pointer"
        >
          <Icon SVG={Logo} width="116px" height="30px" />
        </Flex>
        <BurgerMenu />
      </Flex>
    </Flex>
  );
};

export default HeaderMobile;
