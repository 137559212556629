/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  INITIAL_STATE,
  PENDING_STATE,
  REJECTED_STATE,
  FULFILLED_STATE,
  STORE_NAMES,
} from '@constants';

import { createCommonAsyncThunk } from '@utils/helpers';
import api from './api';

const { PRODUCTS } = STORE_NAMES;

const initialState = {
  ...INITIAL_STATE,
  availableProducts: {
    subscriptions: [],
    non_consumable_products: [],
    consumable_products: [],
  },
  userPurchases: {
    subscription: {},
    non_consumable_products: [],
    consumable_products: [],
  },
  userPaymentFlow: {
    freeDays: 0,
    discount: 0,
    promocode: '',
    promotionName: '',
    isBuyButtonDisabled: true,
    isPaymentProcessing: false,
  },
  userActiveOrder: {
    orderId: '',
  },
  isLoading: false,
};

const getProductsThunkName = `${PRODUCTS}/getProducts`;
const getUserPurchasesThunkName = `${PRODUCTS}/getUserPurchases`;
const createStripeSubscriptionThunkName = `${PRODUCTS}/createStripeSubscription`;
const cancelSubscriptionThunkName = `${PRODUCTS}/cancelSubscription`;
const restoreSubscriptionThunkName = `${PRODUCTS}/restoreSubscription`;
const createStripeCheckoutSessionThunkName = `${PRODUCTS}/createStripeCheckoutSession`;
const checkPromocodeThunkName = `${PRODUCTS}/checkPromocode`;
const getAllSubscriptionsThunkName = `${PRODUCTS}/subscriptions`;

export const getProducts = createCommonAsyncThunk(
  getProductsThunkName,
  api.getProducts,
);

export const getAllSubscriptions = createCommonAsyncThunk(
  getAllSubscriptionsThunkName,
  api.getAllSubscriptions,
);

export const getUserPurchases = createCommonAsyncThunk(
  getUserPurchasesThunkName,
  api.getUserPurchases,
);

export const createStripeSubscription = createCommonAsyncThunk(
  createStripeSubscriptionThunkName,
  api.createStripeSubscription,
);

export const cancelSubscription = createCommonAsyncThunk(
  cancelSubscriptionThunkName,
  api.cancelSubscription,
);

export const restoreSubscription = createCommonAsyncThunk(
  restoreSubscriptionThunkName,
  api.restoreSubscription,
);

export const createStripeCheckoutSession = createCommonAsyncThunk(
  createStripeCheckoutSessionThunkName,
  api.createCheckoutSession,
);

export const checkPromocode = createCommonAsyncThunk(
  checkPromocodeThunkName,
  api.checkPromocode,
);

const productsSlice = createSlice({
  name: PRODUCTS,
  initialState,
  reducers: {
    setFreeDays: (state, action) => ({
      ...state,
      userPaymentFlow: {
        ...state.userPaymentFlow,
        freeDays: action.payload,
      },
    }),
    setDiscount: (state, action) => ({
      ...state,
      userPaymentFlow: {
        ...state.userPaymentFlow,
        discount: action.payload,
      },
    }),
    setPromocode: (state, action) => ({
      ...state,
      userPaymentFlow: {
        ...state.userPaymentFlow,
        promocode: action.payload,
      },
    }),
    setPromotionName: (state, action) => ({
      ...state,
      userPaymentFlow: {
        ...state.userPaymentFlow,
        promotionName: action.payload,
      },
    }),
    setIsPaymentProcessing: (state, action) => ({
      ...state,
      userPaymentFlow: {
        ...state.userPaymentFlow,
        isPaymentProcessing: action.payload,
      },
    }),
    setIsBuyButtonDisabled: (state, action) => ({
      ...state,
      userPaymentFlow: {
        ...state.userPaymentFlow,
        isBuyButtonDisabled: action.payload,
      },
    }),
    resetUserPaymentFlowState: state => ({
      ...state,
      userPaymentFlow: {
        ...initialState.userPaymentFlow,
      },
    }),
    resetUserActiveOrderState: state => ({
      ...state,
      userActiveOrder: {
        ...initialState.userActiveOrder,
      },
    }),
    resetState: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(getAllSubscriptions.fulfilled, (state, action) => ({
        ...state,
        ...FULFILLED_STATE,
        availableProducts: action.payload,
      }))
      .addCase(getUserPurchases.fulfilled, (state, action) => ({
        ...state,
        ...FULFILLED_STATE,
        userPurchases: {
          ...state.userPurchases,
          subscription: action.payload.subscription,
          non_consumable_products: action.payload.non_consumable_products || [],
          consumable_products: action.payload.consumable_products || [],
        },
      }))
      .addCase(getProducts.fulfilled, (state, action) => ({
        ...state,
        ...FULFILLED_STATE,
        userPurchases: {
          ...state.userPurchases,
          non_consumable_products: action.payload.non_consumable_products || [],
          consumable_products: action.payload.consumable_products || [],
        },
      }))
      .addMatcher(isAnyOf(
        cancelSubscription.fulfilled,
        restoreSubscription.fulfilled,
      ), state => ({
        ...state,
        ...FULFILLED_STATE,
        isLoading: false,
      }))
      .addMatcher(isAnyOf(
        createStripeSubscription.fulfilled,
        checkPromocode.fulfilled,
      ), state => ({
        ...state,
        ...FULFILLED_STATE,
      }))
      .addMatcher(isAnyOf(
        getProducts.pending,
        getUserPurchases.pending,
        createStripeSubscription.pending,
        checkPromocode.pending,
      ), state => {
        if (!state.isPending) {
          return {
            ...state,
            ...PENDING_STATE,
          };
        }
      })
      .addMatcher(isAnyOf(
        cancelSubscription.pending,
        restoreSubscription.pending,
        getAllSubscriptions.pending,
      ), state => {
        if (!state.isPending) {
          return {
            ...state,
            ...PENDING_STATE,
            isLoading: true,
          };
        }
      })
      .addMatcher(isAnyOf(
        getProducts.rejected,
        getUserPurchases.rejected,
        createStripeSubscription.rejected,
        checkPromocode.rejected,
        getAllSubscriptions.rejected,
      ), (state, action) => {
        const {
          data: { message, errors },
          status,
        } = action.payload;

        if (state.isPending) {
          const newState = {
            ...state,
            ...REJECTED_STATE,
            error: { message, status, errors },
          };
          return newState;
        }
      })
      .addMatcher(isAnyOf(
        cancelSubscription.rejected,
        restoreSubscription.rejected,
      ), (state, action) => {
        const {
          data: { message, errors },
          status,
        } = action.payload;

        if (state.isPending) {
          const newState = {
            ...state,
            ...REJECTED_STATE,
            error: { message, status, errors },
            isLoading: false,
          };
          return newState;
        }
      });
  },
});

const productsPersistConfig = {
  key: PRODUCTS,
  storage: AsyncStorage,
};

const { actions: productsActions, reducer: productsReducer } = productsSlice;

export const {
  setFreeDays,
  setDiscount,
  setPromocode,
  setPromotionName,
  setIsBuyButtonDisabled,
  setIsPaymentProcessing,
  resetUserPaymentFlowState,
  resetUserActiveOrderState,
} = productsActions;
export { productsActions, productsReducer, productsPersistConfig };
