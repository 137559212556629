import React from 'react';
import { Typography } from '@components';
import { ReactComponent as LogInIcon } from '@assets/LogIn.svg';
import { Link } from 'react-router-dom';
import { routes } from '@utils';
import { useTranslation } from 'react-i18next';
import Flex from '../Flex';
import Icon from '../Icon';

const LogInButton = () => {
  const { t } = useTranslation();

  return (
    <Link to={routes.logIn} style={{ textDecoration: 'none' }}>
      <Flex gap="8px" grow="0" pointer>
        <Typography color="darkSecondary">{t('LogIn')}</Typography>
        <Icon SVG={LogInIcon} />
      </Flex>
    </Link>
  );
};

export default LogInButton;
