import {
  bool, func, oneOf,
} from 'prop-types';
import styled, { css } from 'styled-components';
import { marginMixin, paddingMixin } from '@mixins';

const {
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
} = marginMixin;

const {
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
} = paddingMixin;

const Input = styled.input`
    ${({
    isError,
    theme: {
      border, color, spacing, typography,
    },
    flexBasis,
    size,
  }) => css`
      ${margin}
      ${marginTop}
      ${marginRight}
      ${marginBottom}
      ${marginLeft}

      ${padding}
      ${paddingTop}
      ${paddingRight}
      ${paddingBottom}
      ${paddingLeft}
  
      width: 100%;
      height: 48px;
      border: 1.5px solid;
      border-color: ${isError ? color.sunsetOrange : color.lightGrey}}
      border-radius: ${border.radius.sm};
      padding: ${spacing.xl};
      font-family: ${typography.family};
      font-size: ${typography.size[size || 'xl2']};
      font-weight: ${typography.weight.black};
      line-height: normal;
      outline: none;
      flex-basis: ${flexBasis || ''};
  
      ::placeholder {
        color: ${color.grey};
      }
    `}
  `;

Input.defaultProps = {
  isError: false,
  type: 'text',
  onChange: () => null,
};

Input.propTypes = {
  isError: bool,
  type: oneOf([
    'email',
    'number',
    'tel',
    'text',
    'password',
  ]),
  onChange: func,
};

export default Input;

