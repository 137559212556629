import React from 'react';

import { Flex } from '@components';
import { node, number } from 'prop-types';

const TabContent = ({ id, activeTab, children }) => (
  activeTab === id ? <Flex>{children}</Flex> : null
);

TabContent.propTypes = {
  id: number.isRequired,
  activeTab: number.isRequired,
  children: node.isRequired,
};

export default TabContent;
