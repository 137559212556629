import React from 'react';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import AppleSignin from 'react-apple-signin-auth';
import styled from 'styled-components';

import {
  Flex, Button, Icon, Typography, GoogleLoginButton,
} from '@components';
import {
  FACEBOOK_APP_ID, APPLE_APP_ID, APPLE_REDIRECT_URI,
} from '@constants/common';
import { showErrorToast } from '@utils/helpers';
import { ReactComponent as FacebookIcon } from '@assets/FacebookIcon.svg';
import { ReactComponent as AppleIcon } from '@assets/AppleIcon.svg';

const SocialButtons = ({
  handleFacebookSubmit, isAgreedWithPolicy,
  handleGoogleSubmit, handleAppleSubmit,
}) => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const showAuthErorrNotification = response => {
    showErrorToast(t('Toast.Error'));
  };

  return (
    <>
      <FacebookGoogleBtnsContainer marginBottom="sm" justifyBetween grow="0">
        <FacebookLogin
          appId={FACEBOOK_APP_ID}
          callback={handleFacebookSubmit}
          render={renderProps => (
            <CustomSocailButton
              bgColor="white"
              className="custom-social-btn"
              onClick={renderProps.onClick}
            >
              <Flex justifyCenter itemsCenter height="36px">
                <Icon SVG={FacebookIcon} />
                <Typography
                  marginLeft="md"
                  color="darkSecondary"
                  size="lg"
                >
                  {t('AuthCommonContent.Facebook')}

                </Typography>
              </Flex>
            </CustomSocailButton>
          )}
        />
        <GoogleLoginButton googleSubmit={handleGoogleSubmit} isAgreedWithPolicy={isAgreedWithPolicy} />
      </FacebookGoogleBtnsContainer>
      <Flex justifyCenter>
        <AppleSignin
          authOptions={{
            clientId: APPLE_APP_ID,
            scope: 'email name',
            redirectURI: APPLE_REDIRECT_URI,
            usePopup: true,
            state: '',
            nonce: 'nonce',
          }}
          onSuccess={response => handleAppleSubmit(response)}
          onError={showAuthErorrNotification}
          render={props => (
            <CustomSocailButton
              bgColor="white"
              className="custom-social-btn"
              {...props}
            >
              <Flex justifyCenter itemsCenter height="36px">
                <Icon SVG={AppleIcon} />
                <Typography marginLeft="md" color="darkSecondary" size="lg">{t('AuthCommonContent.Apple')}</Typography>
              </Flex>
            </CustomSocailButton>
          )}
        />
      </Flex>
    </>
  );
};

SocialButtons.propTypes = {
  handleFacebookSubmit: func.isRequired,
  handleGoogleSubmit: func.isRequired,
  handleAppleSubmit: func.isRequired,
  isAgreedWithPolicy: bool.isRequired,
};

const FacebookGoogleBtnsContainer = styled(Flex)`
  gap: 8px;
  @media (max-width: 600px) {
    flex-direction: column;
  }

  @media (min-width: 1024px) and (max-width: 1390px) {
    flex-direction: column;
  }

  @media (min-width: 2660px) {
    justify-content: space-evenly;
  }
`;

const CustomSocailButton = styled(Button)`
  width: 50%;
  max-width: 400px;
  cursor: pointer;

  @media (max-width: 600px) {
    width: 100%;
  }

  @media (min-width: 1024px) and (max-width: 1390px) {
    width: 100%;
  }
`;

export default SocialButtons;
