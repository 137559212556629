import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import {
  Flex, Typography, Icon, InputField, ButtonWithLoader, LanguageSwitcher,
} from '@components';
import { useWindowSize } from '@hooks';
import { changePassword, login } from '@store';
import { clearToken, setToken } from '@utils/storage';
import { routes } from '@utils';
import { showErrorToast } from '@utils/helpers';
import { PASSWORD_REGEX, STORE_NAMES } from '@constants';
import { ReactComponent as ArrowBack } from '@assets/ArrowBack.svg';

import { PreviewImage } from './components';

const ChangePasswordScreen = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isMobile, isDesktop] = useWindowSize();

  const {
    authorization_info: { reset_password_token, email },
    isPending,
  } = useSelector(store => store[STORE_NAMES.AUTH]);

  const handleGoBack = () => {
    history.goBack();
  };

  const handleLogin = result => {
    const { token } = result.payload;
    clearToken();
    setToken(token);
    history.push(routes.subscriptions);
  };

  const handleSubmit = async values => {
    const result = await dispatch(changePassword({
      password: values.newPassword,
      resetPasswordToken: reset_password_token,
    }));

    if (result.error) {
      showErrorToast(result.payload.data.error.message);
    } else {
      const loginResult = await dispatch(login({
        email,
        password: values.newPassword,
        language: 'English',
        isMobile: false,
      }));

      if (loginResult.error) {
        showErrorToast(loginResult.payload.data.error.message);
      } else {
        handleLogin(loginResult);
        history.push(routes.subscriptions);
      }
    }
  };

  const validate = values => {
    const errors = {};

    if (!PASSWORD_REGEX.test(values.newPassword)) {
      errors.invalidPassword = t('Validation.InvalidPassword');
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      newPassword: '',
    },
    validate,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  return (
    <Flex minHeight="100vh">
      {isDesktop && <PreviewImage />}
      <Flex justifyCenter>
        <Flex column maxWidth={isMobile ? '80%' : '55%'} marginTop="140px" position="relative">
          {isDesktop ? (
            <>
              <Flex
                grow="0"
                position="absolute"
                left="0"
                top="135px"
                cursor="pointer"
                onClick={handleGoBack}
              >
                <Icon SVG={ArrowBack} />
              </Flex>
              <Flex justifyCenter marginBottom="80px" grow="0">
                <LanguageSwitcher />
              </Flex>
            </>
          ) : (
            <Flex
              grow="0"
              marginBottom="xl7"
              cursor="pointer"
              onClick={handleGoBack}
            >
              <Icon SVG={ArrowBack} />
            </Flex>
          )}
          <Typography
            color="dark"
            size="xl4"
            weight="bold"
            textAlign="center"
            marginBottom="xl"
          >
            {t('ChangePasswordScreen.Title')}
          </Typography>
          <InputField
            name="newPassword"
            password
            label={t('ChangePasswordScreen.Label')}
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            isError={!!formik.errors.invalidPassword}
          />
          <Flex marginTop="xl4">
            <ButtonWithLoader
              onClick={formik.handleSubmit}
              text={t('ChangePasswordScreen.Button')}
              isLoading={isPending}
            />
          </Flex>
          {!isDesktop && (
            <Flex justifyCenter marginTop="xl6">
              <LanguageSwitcher />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ChangePasswordScreen;
