const getGtmSnippets = ({ gtmId, serverUrl, jsFileName }) => {
  const script = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    '${serverUrl}/${jsFileName}.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gtmId}');
    `;

  const iframe = `
    <iframe src="${serverUrl}/ns.html?id=${gtmId}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;

  return {
    script,
    iframe,
  };
};

const TagManager = {
  gtm(args) {
    const snippets = getGtmSnippets(args);

    const noScript = () => {
      const noscript = document.createElement('noscript');
      noscript.innerHTML = snippets.iframe;
      return noscript;
    };

    const script = () => {
      const scriptElem = document.createElement('script');
      scriptElem.innerHTML = snippets.script;
      return scriptElem;
    };

    return {
      noScript,
      script,
    };
  },

  initialize({ gtmId, serverUrl = 'https://www.googletagmanager.com', jsFileName = 'test' }) {
    // eslint-disable-next-line react/no-is-mounted
    const gtm = this.gtm({ gtmId, serverUrl, jsFileName });

    document.head.insertBefore(gtm.script(), document.head.childNodes[0]);
    document.body.insertBefore(gtm.noScript(), document.body.childNodes[0]);
  },
};

export default TagManager;
