import { apiClient } from '@utils';

import { AUTH_ENDPOINTS, HTTP_METHODS } from '@constants';

const {
  LOGIN,
  REGISTER,
  VERIFY_EMAIL,
  RESET_PASSWORD,
  RESET_PASSWORD_VERIFY,
  CHANGE_PASSWORD,
  LOGOUT,
  DELETE_ACCOUNT,
  CHANGE_EMAIL_VERIFICATION,
} = AUTH_ENDPOINTS;

const { POST, PUT, DELETE } = HTTP_METHODS;

const login = data => apiClient.unauthorizedRequest({
  method: POST,
  url: LOGIN,
  data,
});

const register = data => apiClient.unauthorizedRequest({
  method: POST,
  url: REGISTER,
  data,
});

const verifyEmail = data => apiClient.unauthorizedRequest({
  method: POST,
  url: VERIFY_EMAIL,
  data,
});

const resetPassword = data => apiClient.unauthorizedRequest({
  method: POST,
  url: RESET_PASSWORD,
  data,
});

const verifyResetPassword = data => apiClient.unauthorizedRequest({
  method: POST,
  url: RESET_PASSWORD_VERIFY,
  data,
});

const changePassword = data => apiClient.unauthorizedRequest({
  method: PUT,
  url: CHANGE_PASSWORD,
  data,
});

const logout = () => apiClient.authorizedRequest({
  method: POST,
  url: LOGOUT,
});

const deleteAccount = () => apiClient.authorizedRequest({
  method: DELETE,
  url: DELETE_ACCOUNT,
});

const verifyChangedEmail = data => apiClient.authorizedRequest({
  method: POST,
  url: CHANGE_EMAIL_VERIFICATION,
  data,
});

const api = {
  login,
  register,
  verifyEmail,
  resetPassword,
  verifyResetPassword,
  changePassword,
  logout,
  deleteAccount,
  verifyChangedEmail,
};

export default api;
