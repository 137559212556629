/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  bool, number, shape, string,
} from 'prop-types';

import {
  Flex, Typography, Icon, Button, Spinner,
} from '@components';
import { useWindowSize } from '@hooks';
import { cancelSubscription, getUserPurchases, restoreSubscription } from '@store';
import { showErrorToast, showSuccessToast } from '@utils/helpers';
import { ReactComponent as CheckMarkGreen } from '@assets/CheckMarkGreen.svg';
import { ReactComponent as CrossMarkGrey } from '@assets/CrossMarkGrey.svg';
import { routes } from '@utils';
import { STORE_NAMES } from '@constants';

const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };

const UserSubscriptionBlock = ({ subscription }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isMobile] = useWindowSize();
  const {
    isPending,
  } = useSelector(store => store[STORE_NAMES.PRODUCTS]);

  const handleCancelSubscription = async () => {
    const result = await dispatch(cancelSubscription());
    if (!result.error) {
      await dispatch(getUserPurchases());
      showSuccessToast(t('Purchases.SuccessfullyCanceled'));
    } else {
      showErrorToast(result.payload.data.error.message);
    }
  };

  const handleRestoreSubscription = async () => {
    const result = await dispatch(restoreSubscription());
    if (!result.error) {
      await dispatch(getUserPurchases());
      showSuccessToast(t('Purchases.SuccessfullyRestored'));
    } else {
      showErrorToast(result.payload.data.error.message);
    }
  };

  const handleChangeSubscription = () => {
    history.push(routes.subscriptions);
  };

  if (isPending) {
    return (
      <Flex
        grow="0"
        paddingTop="xl4"
        borderRadius="sm"
        shadow="light"
        justifyCenter
        itemsCenter
        minHeight="400px"
      >
        <Spinner isLoading color="green" size="60px" />
      </Flex>
    );
  }

  return (
    <Flex
      grow="0"
      column
      paddingTop="xl4"
      borderRadius="sm"
      shadow="light"
      justifyCenter
      minHeight="400px"
    >
      {!subscription
        ? (
          <Typography
            marginLeft="xl4"
            marginRight="xl4"
            color="darkSecondary"
            textAlign="center"
          >
            {/* TODO: clarify placeholder text */}
            {t('Purchases.SubscriptionPlaceholder')}
          </Typography>
        )
        : (
          <>
            <BorderedFlex
              column
              paddingRight="xl4"
              paddingLeft="xl4"
            >
              <Flex justifyBetween paddingTop="xl4" marginBottom="xl5">
                <Flex column>
                  <Typography
                    color="greyApple"
                    size="md"
                  >
                    {`${subscription?.duration} ${
                      t(`Subscriptions.${subscription?.duration > 1 ? 'Months' : 'Month'}`)
                    }`}
                  </Typography>
                  <Typography size="xl" color="darkSecondary">{subscription?.title}</Typography>
                </Flex>
                {!isMobile && !subscription?.is_subscription_canceled && (
                  <Button
                    onClick={handleChangeSubscription}
                    bgColor="white"
                    color="darkSecondary"
                    width="135px"
                  >
                    {t('Purchases.Change')}
                  </Button>
                )}
              </Flex>
              <Flex justifyBetween paddingBottom="xl4">
                <Flex
                  grow="0"
                  column
                  marginRight="xl6"
                  style={
                    subscription.isKlarna ? { paddingTop: '13px', paddingBottom: '13px' } : {}
                  }
                >
                  {
                    !subscription.isKlarna && (
                      <Typography weight="bold" size="xl2" color="darkSecondary">
                        {`${subscription.price / 100} kr`}
                      </Typography>
                    )
                  }
                  <Typography
                    color="greyApple"
                    size="md"
                  >
                    {
                      !subscription.is_subscription_paused
                        ? (
                          !subscription.isKlarna
                            ? `${t('Purchases.NextPayment')} ${
                              new Date(subscription?.next_payment_date).toLocaleDateString('en-GB', dateOptions)
                            }`
                            : `${t('Purchases.ActiveTo')} ${
                              new Date(subscription?.next_payment_date).toLocaleDateString('en-GB', dateOptions)
                            }`
                        )
                        : `${t('Purchases.SubscriptionPaused')}`
                    }
                  </Typography>
                </Flex>
                {!isMobile && (
                  <Button
                    // eslint-disable-next-line max-len
                    onClick={subscription?.is_subscription_canceled ? handleRestoreSubscription : handleCancelSubscription}
                    bgColor="white"
                    color="darkSecondary"
                    width="135px"
                  >
                    {t(`Purchases.${subscription?.is_subscription_canceled ? 'Restore' : 'Cancel'}`)}
                  </Button>
                )}
              </Flex>
              {isMobile && (
                <Flex height="48px" marginBottom="xl4" justifyBetween>
                  {!subscription?.is_subscription_canceled
                    && (
                      <Button
                        onClick={handleChangeSubscription}
                        bgColor="white"
                        color="darkSecondary"
                        width="40%"
                      >
                        {t('Purchases.Change')}
                      </Button>
                    )}
                  <Button
                    // eslint-disable-next-line max-len
                    onClick={subscription?.is_subscription_canceled ? handleRestoreSubscription : handleCancelSubscription}
                    bgColor="white"
                    color="darkSecondary"
                    width={subscription?.is_subscription_canceled ? '100%' : '55%'}
                  >
                    {t(`Purchases.${subscription?.is_subscription_canceled ? 'Restore' : 'Cancel'}`)}
                  </Button>
                </Flex>
              )}
            </BorderedFlex>
            <Flex
              padding="xl4"
              wrapFlex
            >
              <FlexWithIcon width="50%" marginBottom="lg">
                <Icon SVG={subscription?.has_diet_plan ? CheckMarkGreen : CrossMarkGrey} width="20px" height="20px" />
                <Typography
                  color="darkSecondary"
                  marginLeft="md"
                >
                  {t('Subscriptions.TableHeaders.DietPlan')}
                </Typography>
              </FlexWithIcon>
              <FlexWithIcon column width="50%" marginBottom="lg">
                <Flex marginBottom="xxs">
                  <Icon SVG={subscription?.has_coaching ? CheckMarkGreen : CrossMarkGrey} width="20px" height="20px" />
                  <Typography
                    color="darkSecondary"
                    marginLeft="md"
                  >
                    {t('Subscriptions.TableHeaders.Coaching')}
                  </Typography>
                </Flex>
                <Typography
                  color="greyApple"
                  size="md"
                >
                  {t(`Subscriptions.${subscription?.has_chat ? 'HasChat' : 'HasNoChat'}`)}
                </Typography>
              </FlexWithIcon>
              <FlexWithIcon width="50%" marginBottom="xl7">
                <Icon SVG={subscription?.has_recipes_db ? CheckMarkGreen : CrossMarkGrey} width="20px" height="20px" />
                <Typography color="darkSecondary" marginLeft="md">{t('Subscriptions.TableHeaders.Recipes')}</Typography>
              </FlexWithIcon>
              <FlexWithIcon width="50%" marginBottom="xl7">
                <Icon
                  SVG={subscription?.has_training_program ? CheckMarkGreen : CrossMarkGrey}
                  width="20px"
                  height="20px"
                />
                <Typography
                  color="darkSecondary"
                  marginLeft="md"
                >
                  {t('Subscriptions.TableHeaders.TrainingProgram')}
                </Typography>
              </FlexWithIcon>
              <FlexWithIcon width="50%">
                <Icon SVG={subscription?.has_booklets ? CheckMarkGreen : CrossMarkGrey} width="20px" height="20px" />
                <Typography
                  color="darkSecondary"
                  marginLeft="md"
                >
                  {t('Subscriptions.TableHeaders.Booklets')}
                </Typography>
              </FlexWithIcon>
              <FlexWithIcon width="50%">
                {/* eslint-disable-next-line max-len */}
                <Icon SVG={subscription?.has_own_training_program ? CheckMarkGreen : CrossMarkGrey} width="20px" height="20px" />
                <Typography color="darkSecondary" marginLeft="md">{t('Subscriptions.TableHeaders.Workout')}</Typography>
              </FlexWithIcon>
            </Flex>
          </>
        )}
    </Flex>
  );
};

const BorderedFlex = styled(Flex)`
  border-bottom: 1px solid rgba(107, 120, 136, 0.1);
`;

const FlexWithIcon = styled(Flex)`
  @media (max-width: 590px) {
    width: 100%;
    margin-bottom: 15px;
  }
`;

UserSubscriptionBlock.propTypes = {
  subscription: shape({
    id: number.isRequired,
    price: number.isRequired,
    price_after_first_payment: number.isRequired,
    duration: number.isRequired,
    title: string.isRequired,
    has_chat: bool.isRequired,
    has_diet_plan: bool.isRequired,
    has_training_program: bool.isRequired,
    has_own_training_program: bool.isRequired,
    has_coach: bool.isRequired,
    has_coaching: bool.isRequired,
    has_booklets: bool.isRequired,
    has_recipes_db: bool.isRequired,
  }).isRequired,
};

export default UserSubscriptionBlock;
