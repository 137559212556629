import React from 'react';
import { number, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Flex, Typography } from '@components';
import { useWindowSize } from '@hooks';
import { PRODUCT_TYPES } from '@constants/common';

const ProductBlock = ({ product }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [isMobile, isDesktop] = useWindowSize();

  const handleProductType = type => {
    switch (type) {
      case PRODUCT_TYPES.booklet:
        return t('Products.Booklet');
      case PRODUCT_TYPES.document:
        return t('Products.E-book');
      case PRODUCT_TYPES.trainingProgram:
        return t('Products.TrainingProgram');
      case PRODUCT_TYPES.dietPlan:
        return t('Products.DietPlan');
      default:
        return 'Product';
    }
  };

  return (
    <Flex
      grow="0"
      column
      padding="xl4"
      marginRight="xl6"
      marginBottom="xl"
      borderRadius="sm"
      shadow="light"
      maxHeight="160px"
      width={isDesktop ? '50%' : '100%'}
    >
      <Typography size="md" color="greyApple">{handleProductType(product.type)}</Typography>
      <Typography size="xl3" color="darkSecondary" marginBottom="xl4">{product.name}</Typography>
      <Typography size="xl4" weight="bold" color="darkSecondary">{`${product.price / 100} kr`}</Typography>
    </Flex>
  );
};

ProductBlock.propTypes = {
  product: shape({
    id: number,
    name: string,
    price: number,
    type: string,
  }),
};

ProductBlock.defaultProps = {
  product: {},
};

export default ProductBlock;
