import React from 'react';
import { bool, func, string } from 'prop-types';
import styled, { css } from 'styled-components';

import { Flex, Typography, Icon } from '@components';
import { ReactComponent as CheckMarkGreen } from '@assets/CheckboxCheckMark.svg';

const Checkbox = ({
  value, label, onChange, checkboxId, textColor, textSize,
  marginBottom, marginTop, gap,
}) => (
  <StyledLabel
    htmlFor={checkboxId}
    marginBottom={marginBottom}
    marginTop={marginTop}
  >
    <StyledCheckbox id={checkboxId} type="checkbox" checked={value} onChange={onChange} />
    {value && (
      <CheckMarkWrapper>
        <Icon SVG={CheckMarkGreen} width="16px" height="16px" />
      </CheckMarkWrapper>
    )}
    <Typography color={textColor} size={textSize} marginLeft={gap}>{label}</Typography>
  </StyledLabel>
);

Checkbox.propTypes = {
  value: bool.isRequired,
  label: string,
  onChange: func,
  checkboxId: string.isRequired,
  textColor: string,
  textSize: string,
  marginTop: string,
  marginBottom: string,
  gap: string,
};

Checkbox.defaultProps = {
  label: '',
  textColor: 'darkSecondary',
  textSize: 'lg',
  marginBottom: '0',
  marginTop: '0',
  gap: '0',
  onChange: () => {},
};

const StyledLabel = styled.label`
    display: flex;
    align-items: center;
    position: relative;
    ${props => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
    ${props => props.marginTop && `margin-top: ${props.marginTop};`}
`;

const StyledCheckbox = styled.input`
    ${({ theme: { color, border } }) => css`
        -webkit-appearance: none;
        appearance: none;

        min-width: 24px;
        min-height: 24px;
        border: 2px solid ${color.greyApple};
        border-radius: ${border.radius.xs};

        :checked {
            border-color: ${color.green};
        }
    `}
`;

const CheckMarkWrapper = styled(Flex)`
        position: absolute;
        left: 8px;
`;

export default Checkbox;
