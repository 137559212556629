import React, { useEffect, useRef } from 'react';
import { Flex, Typography } from '@components';
import { useWindowSize } from '@hooks';
import { createStripeSubscription } from '@store';
import {
  useElements, useStripe, CardNumberElement, CardExpiryElement, CardCvcElement,
} from '@stripe/react-stripe-js';
import { theme } from '@styles';
import { routes } from '@utils';
import {
  bool, func, number, shape, string,
} from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PAYMENT_SUCCEEDED, SUBSCRIPTION_DOWNGRADED, TRACKING_PRODUCT_TYPES } from 'src/constants/common';
import { clearGtmDataLayer, showErrorToast, showSuccessToast } from 'src/utils/helpers';
import styled, { css } from 'styled-components';
import { STORE_NAMES } from '@constants';
import {
  resetUserPaymentFlowState,
  setDiscount,
  setIsBuyButtonDisabled,
  setIsPaymentProcessing,
  setPromocode,
  setPromotionName,
} from 'src/store/products/duck';
import BuySubscriptionBlock from '../../BuySubscriptionBlock/BuySubscriptionBlock';

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: theme.typography.family,
      fontSize: theme.typography.size.xl,
      fontWeight: theme.typography.weight.regular,
      height: '48px',

      '::placeholder': {
        color: theme.color.grey,
      },
    },
  },
};

const CARD_NUMBER_ELEMENT_OPTIONS = {
  showIcon: true,
  hidePostalCode: true,
  ...ELEMENT_OPTIONS,
};

const PayWithCardTab = ({
  subscription, trackInitiateCheckout, onSubmit, isFormDisabled, emailValue,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [, isDesktop] = useWindowSize();
  const stripe = useStripe();
  const elements = useElements();

  const {
    availableProducts: { has_active_subscription },
    userPaymentFlow: {
      discount,
      freeDays,
      promocode,
      promotionName,
    },
    userPurchases: { subscription: purchasedSubscription },
  } = useSelector(store => store[STORE_NAMES.PRODUCTS]);
  const { authorization_info } = useSelector(store => store[STORE_NAMES.AUTH]);

  const isAddPaymentInfoTracked = useRef(false);

  useEffect(() => {
    dispatch(resetUserPaymentFlowState());
  }, []);

  const trackGTMAddPaymentInfo = ({ sub, promotionProps = {} }) => {
    clearGtmDataLayer();

    window.dataLayer.push({
      event: 'add_payment_info',
      eventProps: {
        currency: 'NOK',
        value: typeof promotionProps.discount === 'number'
          ? (sub.price - promotionProps.discount) / 100
          : sub.price / 100,
        coupon: promotionProps.coupon,
        payment_type: 'card',
        items: [{
          item_id: sub.id,
          item_name: sub.title,
          item_category: TRACKING_PRODUCT_TYPES.subscription,
        }],
      },
      meta: {
        email: authorization_info.email,
        eventProps: {
          content_category: 'fitness',
          content_ids: [sub.id],
          contents: [{
            id: sub.id,
            quantity: 1,
          }],
          value: typeof promotionProps.discount === 'number'
            ? (sub.price - promotionProps.discount) / 100
            : sub.price / 100,
          currency: 'NOK',
        },
      },
      userProps: {
        user_id: authorization_info.id,
        subscription_id: sub.id,
      },
    });
  };

  const handleDisablePaymentButton = element => {
    if (!isAddPaymentInfoTracked.current) {
      trackGTMAddPaymentInfo({
        sub: subscription,
        promotionProps: {
          discount,
          coupon: promocode,
          promotionName,
        },
      });
      isAddPaymentInfoTracked.current = true;
    }

    dispatch(setIsBuyButtonDisabled(!element.complete));
  };

  const finishSubmit = () => {
    dispatch(setPromotionName(''));
    dispatch(setPromocode(''));
    dispatch(setDiscount(0));

    elements.getElement('card')?.clear();

    dispatch(setIsPaymentProcessing(false));
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    dispatch(setIsPaymentProcessing(true));

    trackInitiateCheckout({
      sub: subscription,
      promotionProps: {
        discount,
        coupon: promocode,
        promotionName,
      },
    });

    let paymentMethodId = null;
    if (discount === subscription.price || freeDays) {
      const res = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement('card'),
      });

      if (res.error) {
        showErrorToast(res.error.message);
      }

      paymentMethodId = res.paymentMethod.id;
    }

    const { customer } = await onSubmit();

    if (!customer) {
      finishSubmit();
      return;
    }

    const result = await dispatch(createStripeSubscription({
      query: { isInitialBuy: !has_active_subscription },
      data: {
        subscriptionId: subscription.id,
        promocode,
        paymentMethodId,
        customerId: customer.id,
      },
    }));

    if (result.error) {
      showErrorToast(result.payload.data.error.message);
    } else {
      if (result.payload.status === SUBSCRIPTION_DOWNGRADED) {
        showSuccessToast(t('Toast.Downgraded'));
        finishSubmit();
        return;
      }

      if (!result.payload.client_secret) {
        history.push(`${routes.success}?subscriptionId=${subscription.id}`);
        return;
      }

      const response = await stripe.confirmCardPayment(result.payload.client_secret, {
        payment_method: {
          card: elements.getElement('cardNumber'),
        },
        setup_future_usage: 'off_session',
      });

      if (response.error) {
        showErrorToast(response.error.message);
      } else if (response.paymentIntent.status === PAYMENT_SUCCEEDED) {
        history.push(`${routes.success}?subscriptionId=${subscription.id}`, {
          paymentIntentId: response.paymentIntent.id,
          promocode,
          discount,
          promotionName,
        });
      }
    }

    finishSubmit();
  };

  return (
    <Wrapper
      column
      justifyStart
      width={isDesktop ? '50%' : '100%'}
      rowGap={isDesktop ? '32px' : '20px'}
      marginTop="32px"
    >
      {purchasedSubscription
      && purchasedSubscription.price >= subscription.price
      && purchasedSubscription.duration !== subscription.duration
      && (
        <Typography color="grey" padding="sm" paddingBottom="0">
          {t('GetSubscription.Warning')}
        </Typography>
      )}
      <Flex column grow="0" gap="16px">
        <CardNumberElement onChange={handleDisablePaymentButton} options={CARD_NUMBER_ELEMENT_OPTIONS} />
        <Flex gap="16px">
          <CardExpiryElement onChange={handleDisablePaymentButton} options={CARD_NUMBER_ELEMENT_OPTIONS} />
          <CardCvcElement onChange={handleDisablePaymentButton} options={CARD_NUMBER_ELEMENT_OPTIONS} />
        </Flex>
      </Flex>
      <BuySubscriptionBlock
        handleSubmit={handleSubmit}
        subscriptionId={subscription.id}
        subscriptionPrice={subscription.price}
        isStripeLoaded={Boolean(stripe)}
        isProduct={false}
        isFormDisabled={isFormDisabled}
        emailValue={emailValue}
      />
    </Wrapper>
  );
};

PayWithCardTab.propTypes = {
  subscription: shape({
    id: number.isRequired,
    price: number.isRequired,
    price_after_first_payment: number.isRequired,
    duration: number.isRequired,
    title: string.isRequired,
    title_no: string.isRequired,
    has_chat: bool.isRequired,
    has_diet_plan: bool.isRequired,
    has_training_program: bool.isRequired,
    has_own_training_program: bool.isRequired,
    has_coach: bool.isRequired,
    has_coaching: bool.isRequired,
    has_booklets: bool.isRequired,
    has_recipes_db: bool.isRequired,
  }),
  trackInitiateCheckout: func.isRequired,
  onSubmit: func.isRequired,
  isFormDisabled: bool.isRequired,
  emailValue: string.isRequired,
};

PayWithCardTab.defaultProps = {
  subscription: {},
};

const Wrapper = styled(Flex)`
  ${({ theme: { color, spacing, border } }) => css`
  .StripeElement {
    width: 100%;
    padding: ${spacing.sm};
    border: 1.5px solid ${color.lightGrey};
    border-radius: ${border.radius.sm};
  }
  `}
`;

export default PayWithCardTab;
