import React, { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import { gtmClient } from '@utils';

import { Router } from '@router';
import { store, persistor } from '@store/config';
import {
  FontStyle, GlobalStyle, ResetStyle, theme,
} from '@styles';
import {
  GOOGLE_TAG_MANAGER_ID,
  UTM_PARAMS,
  GOOGLE_TAG_SERVER_URL,
  GOOGLE_TAG_JS_FILE_NAME,
} from '@constants/common';
import {
  clearIsCheckoutSessionActivated,
  getIsCheckoutSessionActivated,
  setUtmParameters,
} from './utils/storage';

import i18n from './i18n';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  useEffect(() => {
    gtmClient.initialize({
      gtmId: GOOGLE_TAG_MANAGER_ID,
      serverUrl: GOOGLE_TAG_SERVER_URL,
      jsFileName: GOOGLE_TAG_JS_FILE_NAME,
    });

    // save UTM parameters from url when app is loaded
    const isCheckoutSessionActivated = getIsCheckoutSessionActivated();
    if (!isCheckoutSessionActivated) {
      const query = new URLSearchParams(window.location.search);

      const utmParamsObject = {};
      Object.values(UTM_PARAMS).forEach(paramName => {
        if (query.has(paramName)) {
          utmParamsObject[paramName] = query.get(paramName);
        }
      });
      setUtmParameters(utmParamsObject);
      clearIsCheckoutSessionActivated();
    }
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <ResetStyle />
            <FontStyle />
            <GlobalStyle />
            <Router />
            <StyledToastContainer
              position="top-right"
              hideProgressBar
              theme="dark"
            />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </I18nextProvider>
  );
};

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    border-radius: 12px;
  }

  .Toastify__toast-theme--dark {
    background-color: rgba(0, 22, 47, 0.7);
  }
`;

export default App;
