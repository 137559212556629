import { stripeConfig } from '@utils';
import { Elements } from '@stripe/react-stripe-js';
import React from 'react';
import { shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PaymentBlock } from '../../screens/Subscription/components';

const isKlarnaCheckoutAvailable = subscription => !subscription?.store_subscription_id?.includes('app.access');

const CheckoutBlock = ({ subscription }) => {
  const { i18n } = useTranslation();

  return (
    <Elements
      stripe={stripeConfig.stripe}
      options={{
        locale: i18n.language,
        ...stripeConfig.stripeOptions,
      }}
    >
      <PaymentBlock
        subscription={subscription}
        isKlarnaDisabled={!isKlarnaCheckoutAvailable(subscription)}
      />
    </Elements>
  );
};

CheckoutBlock.propTypes = {
  subscription: shape({}).isRequired,
};

export default CheckoutBlock;
