import { Flex, Links, Typography } from '@components';
import React from 'react';
import { useWindowSize } from '@hooks';
import AppStoreLink from '@assets/AppStoreLink.png';
import GooglePlayLink from '@assets/GooglePlayLink.png';
import { bool, node, string } from 'prop-types';
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from '../../constants/common';
import { AppStoreLinkIcon, GooglePlayLinkIcon } from './styles';
import Icon from '../Icon';

const SuccessStatus = ({
  title, subtitle, SVG, withSocialLinks,
}) => {
  const [isMobile, isDesktop] = useWindowSize();

  return (
    <Flex
      justifyCenter
      itemsCenter
      column
      rowGap="32px"
    >
      <Flex
        padding={`${isDesktop ? '48px' : '48px 24px'}`}
        columnGap="48px"
        borderRadius={`${isDesktop ? '400px' : '100px'}`}
        bgColor="white"
        maxWidth="800px"
        column={isMobile}
        justifyCenter={isMobile}
        itemsCenter
      >
        <Icon width={160} height={160} SVG={SVG} />
        <Flex column marginTop={`${isDesktop ? '0' : '24px'}`}>
          <Typography
            color="darkSecondary"
            size={`${isDesktop ? 'xl4' : 'xl'}`}
            weight="bold"
            lineHeight="130%"
            marginBottom={`${isDesktop ? '16px' : '12px'}`}
            textAlign={`${isDesktop ? 'auto' : 'center'}`}
          >
            {title}
          </Typography>
          <Typography
            color="darkSecondary"
            size={`${isDesktop ? 'xl' : 'md'}`}
            marginBottom="32px"
            textAlign={`${isDesktop ? 'auto' : 'center'}`}
          >
            {subtitle}
          </Typography>
          {
            withSocialLinks && (
              <Flex
                columnGap="32px"
                rowGap="24px"
                justifyStart
                column={isMobile}
              >
                <Links.Button
                  to={{ pathname: APP_STORE_LINK }}
                  target="_blank"
                  width="auto"
                >
                  <AppStoreLinkIcon alt="App store link" src={AppStoreLink} />
                </Links.Button>
                <Links.Button
                  to={{ pathname: GOOGLE_PLAY_LINK }}
                  target="_blank"
                  width="auto"
                >
                  <GooglePlayLinkIcon alt="Google Play link" src={GooglePlayLink} />
                </Links.Button>
              </Flex>
            )
          }
        </Flex>
      </Flex>
    </Flex>
  );
};

SuccessStatus.defaultProps = {
  withSocialLinks: false,
};

SuccessStatus.propTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
  SVG: node.isRequired,
  withSocialLinks: bool,
};

export default SuccessStatus;
