import React, { useEffect, useState } from 'react';
import { Chip } from '@components';
import { arrayOf, shape, string } from 'prop-types';
import { useWindowSize } from '@hooks';
import { useTranslation } from 'react-i18next';
import Typography from '../Typography';
import FeatureCard from '../FeatureCard/FeatureCard';
import { ChipsContainer, Container } from './styles';

const SubscriptionFeatures = ({ features }) => {
  const [selectedFeature, setSelectedFeature] = useState(features[0]);

  const { t } = useTranslation();
  const [isMobile] = useWindowSize();

  useEffect(() => {
    setSelectedFeature(features[0]);
  }, [features.length]);

  const handleFeatureSelect = feature => () => {
    setSelectedFeature(feature);
  };

  return (
    <Container>
      <Typography color="greyApple" size="md">{t('SubscriptionsInfo.Title')}</Typography>
      <ChipsContainer isMobile={isMobile}>
        {features.map(featureItem => (
          <Chip
            label={t(featureItem.title)}
            onClick={handleFeatureSelect(featureItem)}
            key={featureItem.id}
            isActive={featureItem.id === selectedFeature?.id}
          />
        ))}
      </ChipsContainer>
      <FeatureCard
        description={t(selectedFeature?.description)}
        title={t(selectedFeature?.title)}
        imageSrc={selectedFeature?.imageSrc}
      />
    </Container>
  );
};

SubscriptionFeatures.propTypes = {
  features: arrayOf(shape({
    id: string,
    title: string,
    description: string,
    imageSrc: string,
  })).isRequired,
};

export default SubscriptionFeatures;
