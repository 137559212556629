import { useQuery, useWindowSize } from '@hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Flex, Header, HeaderMobile, Spinner,
} from '@components';
import { storage } from '@utils';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EmailIcon } from '@assets/Email.svg';
import { verifyChangedEmail } from '../../store/authorization/duck';
import SuccessStatus from '../../components/SuccessStatus/SuccessStatus';

const EmailVerification = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const [isVerified, setIsVerified] = useState(false);
  const [, isDesktop] = useWindowSize();
  const { t } = useTranslation();

  const isAuthorized = !!storage.getToken();

  useEffect(async () => {
    const result = await dispatch(verifyChangedEmail({
      ott: query.get('ott'),
      email: query.get('email'),
    }));

    if (result.error) {
      toast.error('Something went wrong');
    } else {
      toast.success('Successfully verified');
      setIsVerified(true);
    }
  }, []);

  if (!isVerified) return <Spinner />;

  return (
    <Flex column minHeight="100vh">
      {isAuthorized && (
        isDesktop ? <Header /> : <HeaderMobile />
      )}
      <Flex
        grow="0"
        justifyCenter
        itemsCenter
        bgImage="linear-gradient(89.94deg, #1BC98E 0.05%, #4DA1F4 99.96%)"
        minHeight={isAuthorized ? 'calc(100vh - 72px)' : '100vh'}
        padding="10px"
      >
        <SuccessStatus
          SVG={EmailIcon}
          title={t('EmailVerification.Title')}
          subtitle={t('EmailVerification.Subtitle')}
        />
      </Flex>
    </Flex>
  );
};

export default EmailVerification;
