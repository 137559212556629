import { STORE_NAMES } from '@constants';

import { createCommonAsyncThunk } from '@utils/helpers';
import api from './api';

const { ANALYTICS } = STORE_NAMES;

const trackViewContentThunkName = `${ANALYTICS}/trackViewContent`;
const trackLeadThunkName = `${ANALYTICS}/trackLead`;
const trackInitiateCheckoutThunkName = `${ANALYTICS}/trackInitialCheckout`;
const trackPurchaseThunkName = `${ANALYTICS}/trackPurchase`;
const getPurchaseDataThunkName = `${ANALYTICS}/getPurchaseGTMTrackingData`;

export const trackViewContent = createCommonAsyncThunk(
  trackViewContentThunkName,
  api.trackViewContent,
);

export const trackInitiateCheckout = createCommonAsyncThunk(
  trackInitiateCheckoutThunkName,
  api.trackInitiateCheckout,
);

export const trackPurchase = createCommonAsyncThunk(
  trackPurchaseThunkName,
  api.trackPurchase,
);

export const getPurchaseGTMTrackingData = createCommonAsyncThunk(
  getPurchaseDataThunkName,
  api.getPurchaseGTMData,
);

export const trackLead = createCommonAsyncThunk(
  trackLeadThunkName,
  api.trackLead,
);
