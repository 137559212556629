import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import {
  Flex, Typography, InputField, Icon, Links, ButtonWithLoader, LanguageSwitcher,
} from '@components';
import { login } from '@store';
import { EMAIL_REGEX, PASSWORD_REGEX, STORE_NAMES } from '@constants';
import { routes } from '@utils';
import {
  clearToken, setToken, clearSubscriptionId, getSubscriptionId,
} from '@utils/storage';
import { showErrorToast, clearGtmDataLayer } from '@utils/helpers';
import { useQuery, useWindowSize } from '@hooks';
import { ReactComponent as ArrowBack } from '@assets/ArrowBack.svg';

import { PreviewImage, SocialButtons } from './components';

const LogInScreen = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const [isMobile, isDesktop] = useWindowSize();

  const [subscriptionId] = useState(getSubscriptionId());

  const { authorization_info, isPending } = useSelector(store => store[STORE_NAMES.AUTH]);

  useEffect(() => {
    const token = query.get('token');

    if (!token) {
      return;
    }

    handleLogin({
      result: { payload: { token, ...authorization_info } },
      method: 'email',
    });
  }, []);

  const removeSubscriptionId = () => {
    if (subscriptionId) {
      clearSubscriptionId();
    }
  };

  const handleGoBack = () => {
    history.push(routes.root);
  };

  const trackGTMLogin = ({
    loginMethod,
    customerId,
    subscriptionStatus,
    signupDate,
    loginPlatform,
  }) => {
    clearGtmDataLayer();
    window.dataLayer.push({
      event: 'login',
      eventProps: {
        method: loginMethod,
      },
      userProps: {
        user_id: customerId,
        subscription_status: subscriptionStatus,
        signup_date: signupDate,
        login_platform: loginPlatform,
      },
    });
  };

  const handleLogin = ({ result, method }) => {
    const {
      token, id, has_subscription, signup_date,
    } = result.payload;

    clearToken();
    removeSubscriptionId();
    setToken(token);

    trackGTMLogin({
      loginMethod: method,
      customerId: id,
      subscriptionStatus: has_subscription ? 'active' : 'inactive',
      signupDate: signup_date,
      loginPlatform: isDesktop ? 'desktop' : 'mobile',
    });

    const historyPath = subscriptionId
      ? `${routes.subscriptions}/${subscriptionId}`
      : routes.subscriptions;

    history.push(historyPath);
  };

  const validate = values => {
    const errors = {};

    if (!PASSWORD_REGEX.test(values.password)) {
      errors.invalidPassword = t('Validation.InvalidPassword');
    }

    if (!EMAIL_REGEX.test(values.email)) {
      errors.invalidEmail = t('Validation.InvalidEmail');
    }

    return errors;
  };

  const handleFacebookSubmit = async response => {
    const result = await dispatch(login({
      authToken: response.accessToken,
      provider: 'facebook',
      language: 'English',
      isMobile: false,
    }));

    if (result.error) {
      showErrorToast(result.payload.data.error.message);
    } else {
      handleLogin({ result, method: 'SSO' });
    }
  };

  const handleGoogleSubmit = async response => {
    const result = await dispatch(login({
      authToken: response.credential,
      provider: 'google',
      language: 'English',
      isMobile: false,
    }));

    if (result.error) {
      showErrorToast(result.payload.data.error.message);
    } else {
      handleLogin({ result, method: 'SSO' });
    }
  };

  const handleAppleSubmit = async response => {
    const result = await dispatch(login({
      authToken: response.authorization.id_token,
      provider: 'apple',
      language: 'English',
      isMobile: false,
    }));

    if (result.error) {
      showErrorToast(result.payload.data.error.message);
    } else {
      handleLogin({ result, method: 'SSO' });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validate,
    validateOnChange: false,
    onSubmit: async values => {
      const { email, password } = values;
      const result = await dispatch(login({
        email, password, language: 'English', isMobile: false,
      }));

      if (result.error) {
        showErrorToast(result.payload.data.error.message);
      } else {
        handleLogin({ result, method: 'email' });
      }
    },
  });

  return (
    <Flex minHeight="100vh">
      {isDesktop && <PreviewImage />}
      <Flex justifyCenter itemsCenter>
        <Flex column maxWidth={isMobile ? '80%' : '60%'}>
          {isDesktop ? (
            <Flex justifyCenter marginBottom="45px">
              <LanguageSwitcher />
            </Flex>
          ) : (
            <Flex cursor="pointer" onClick={handleGoBack} marginTop="xl4">
              <Icon SVG={ArrowBack} />
            </Flex>
          )}
          <Typography
            marginBottom="sm"
            color="dark"
            size="xl4"
            weight="bold"
            textAlign="center"
          >
            {t('LogInScreen.Title')}
          </Typography>
          <Flex marginBottom="xl6" selfCenter>
            <Typography size="lg" color="darkSecondary" marginRight="xxs">{t('LogInScreen.Subtitle')}</Typography>
            <Links.Text to={routes.signUp} color="green">{t('LogInScreen.SignUp')}</Links.Text>
          </Flex>
          <InputField
            name="email"
            label={t('AuthCommonContent.Email')}
            value={formik.values.email}
            onChange={formik.handleChange}
            isError={!!formik.errors.invalidEmail}
          />
          <InputField
            name="password"
            password
            label={t('AuthCommonContent.Password')}
            onChange={formik.handleChange}
            isError={!!formik.errors.invalidPassword}
          />
          <Flex justifyEnd marginBottom="xl">
            <Links.Text
              to={routes.resetPassword}
              color="green"
              textAlign="end"
              size="sm"
              height="16px"
            >
              {t('LogInScreen.ResetPassword')}
            </Links.Text>
          </Flex>
          <ButtonWithLoader
            onClick={formik.handleSubmit}
            text={t('LogInScreen.Title')}
            isLoading={isPending}
          />
          <Typography
            marginTop="xl"
            marginBottom="xl"
            color="greySecondary"
            size="md"
            textAlign="center"
          >
            {t('AuthCommonContent.ContinueWith')}
          </Typography>
          <Flex selfCenter column marginBottom="xl4" width="100%">
            <SocialButtons
              handleFacebookSubmit={handleFacebookSubmit}
              handleGoogleSubmit={handleGoogleSubmit}
              handleAppleSubmit={handleAppleSubmit}
            />
          </Flex>
          {!isDesktop && (
            <Flex justifyCenter marginTop="xl6">
              <LanguageSwitcher />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LogInScreen;
