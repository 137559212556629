import React, { useState, useEffect } from 'react';
import { bool, node, string } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { storage, routes } from '@utils';

const AuthorizedRoute = ({ exact, path, children }) => {
  const { pathname } = window.location;

  const [isAuthorized, setIsAuthorized] = useState(!!storage.getToken());
  // const [subscriptionId, setSubscriptionId] = useState(null);

  useEffect(() => {
    checkOnSubscriptionId(isAuthorized);

    storage.addChangeListener(checkOnAuthorization);

    return () => {
      storage.removeChangeListener(checkOnAuthorization);
    };
  }, []);

  const checkOnAuthorization = () => {
    setIsAuthorized(!!storage.getToken());
  };

  const checkOnSubscriptionId = isAuth => {
    let subId;
    if (path.includes(routes.subscriptions) && !path.endsWith(routes.subscriptions)) {
      [, subId] = pathname.replace(routes.subscriptions, '').split('/');
    }

    if (subId && !isAuth) {
      storage.setSubscriptionId(subId);
      // setSubscriptionId(subId);
    }
  };

  return (
    <Route
      exact={exact}
      path={path}
      render={
        () => {
          switch (true) {
            case Boolean(storage.getSubscriptionId() && !isAuthorized):
              return <Redirect to={routes.signUp} />;
            case isAuthorized:
              return children;
            default:
              return <Redirect to={routes.logIn} />;
          }
        }
      }
    />
  );
};

AuthorizedRoute.defaultProps = {
  exact: false,
};

AuthorizedRoute.propTypes = {
  exact: bool,
  path: string.isRequired,
  children: node.isRequired,
};

export default AuthorizedRoute;
