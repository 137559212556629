import React, { useState } from 'react';
import { bool, func, string } from 'prop-types';
import {
  Flex, Input, Label, Typography,
} from '@components';
import { useTranslation } from 'react-i18next';

const InputField = ({
  label, onChange, password, isError, value, name,
}) => {
  const { t } = useTranslation();
  const [isPasswordShown, setShowPassword] = useState(!password);

  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };

  return (
    <Flex marginTop={password ? 'xl4' : 'none'} column grow="0">
      <Label marginBottom="md" color="dark" weight="bold">{label}</Label>
      <Flex position="relative">
        <Input
          marginBottom="xxs"
          name={name}
          type={isPasswordShown ? 'text' : 'password'}
          value={value}
          onChange={onChange}
          isError={isError}
        />
        {password && (
          <Flex position="absolute" right="16px" top="16px">
            <Typography
              lineHeight="16px"
              size="sm"
              color="grey"
              cursor="pointer"
              uppercase
              onClick={togglePasswordVisibility}
            >
              {t(`AuthCommonContent.${isPasswordShown ? 'Hide' : 'Show'}`)}
            </Typography>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

InputField.propTypes = {
  label: string,
  onChange: func.isRequired,
  password: bool,
  isError: bool,
  value: string.isRequired,
  name: string.isRequired,
};

InputField.defaultProps = {
  label: '',
  password: false,
  isError: false,
};

export default InputField;
