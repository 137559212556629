import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import {
  BackgroundImage, Flex, Icon, Links, Typography,
} from '@components';
import { useWindowSize } from '@hooks';
import { routes } from '@utils';
import GetInShapeMainImage from '@assets/GetInShapeMainImage.png';
import { ReactComponent as ArrowBackWhite } from '@assets/ArrowBackWhite.svg';
import { ReactComponent as GetInShapeText } from '@assets/GetInShapeText.svg';

const RootScreen = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [, isDesktop] = useWindowSize();

  if (isDesktop) {
    history.push(routes.subscriptions);
  }

  const goBack = e => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <BackgroundImage
      minHeight="100vh"
      justifyCenter
      imageSrc={GetInShapeMainImage}
    >
      <Flex
        column
        paddingTop="xl8"
        paddingRight="xl"
        paddingBottom="xl8"
        paddingLeft="xl"
        itemsCenter
      >
        <Flex selfStart cursor="pointer">
          <Link to=".." onClick={goBack} target="_blank">
            <Icon SVG={ArrowBackWhite} width="36px" height="36px" />
          </Link>
        </Flex>
        <Icon SVG={GetInShapeText} height="56px" width="300px" />
        <Typography
          color="white"
          size="xl2"
          marginBottom="xl8"
          paddingTop="xl4"
          textAlign="center"
        >
          {t('AuthCommonContent.AuthorizationText')}
        </Typography>
        <Links.Button
          to={routes.signUp}
          bgColor="green"
          color="white"
          marginBottom="xl6"
        >
          {t('SignUpScreen.Title')}
        </Links.Button>
        <Links.Text
          to={routes.logIn}
          color="green"
        >
          {t('LogInScreen.Title')}
        </Links.Text>
      </Flex>
    </BackgroundImage>
  );
};

export default RootScreen;
