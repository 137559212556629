import styled, { css } from 'styled-components';
import { node } from 'prop-types';
import { flexbox, marginMixin, paddingMixin } from '@mixins';

const {
  flex,
  flexDisplay,
  flexDirection,
  flexWrap,
  alignContent,
  alignItems,
  alignSelf,
  justifyContent,
} = flexbox;

const {
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
} = marginMixin;

const {
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
} = paddingMixin;

const Flex = styled.div`
  ${({
    width,
    minWidth,
    maxWidth,
    height,
    minHeight,
    maxHeight,
    position,
    top,
    right,
    bottom,
    left,
    zIndex,
    cursor,
    shadow,
    borderRadius,
    bgColor,
    bgImage,
    rowGap,
    columnGap,
    gap,
    theme: { color: colorFromTheme, boxShadow, border },
  }) => css`
  ${alignContent}
  ${alignItems}
  ${alignSelf}
  ${flex}
  ${flexDirection}
  ${flexDisplay}
  ${flexWrap}
  ${justifyContent}

  ${margin}
  ${marginTop}
  ${marginRight}
  ${marginBottom}
  ${marginLeft}

  ${padding}
  ${paddingTop}
  ${paddingRight}
  ${paddingBottom}
  ${paddingLeft}

  ${width && `
    width: ${width};
  `}

  ${minWidth && `
    min-width: ${minWidth};
  `}

  ${maxWidth && `
    max-width: ${maxWidth};
  `}

  ${height && `
    height: ${height};
  `}

  ${minHeight && `
    min-height: ${minHeight};
  `}

  ${maxHeight && `
    max-height: ${maxHeight};
  `}

  ${position && `
    position: ${position};
  `}

  ${top && `
    top: ${top};
  `}

  ${right && `
    right: ${right};
  `}

  ${bottom && `
    bottom: ${bottom};
  `}

  ${left && `
    left: ${left};
  `}

  ${zIndex && `
    z-index: ${zIndex};
  `}

  ${cursor && `
    cursor: ${cursor};
  `}

  ${shadow && `
    box-shadow: ${boxShadow[shadow]};
  `}

  ${borderRadius && `
    border-radius: ${border.radius[borderRadius] || borderRadius};
  `}

  ${bgColor && `
    background-color: ${colorFromTheme[bgColor]};
  `}

  ${bgImage && `
    background-image: ${bgImage};
  `}

  ${rowGap && `
    row-gap: ${rowGap};
  `}

  ${columnGap && `
    column-gap: ${columnGap};
  `}

  ${gap && `
    gap: ${gap};
  `}

  `}
`;

Flex.propTypes = {
  children: node.isRequired,
};

export default Flex;
