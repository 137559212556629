import React, {
  useEffect,
  useState,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { useWindowSize } from '@hooks';
import {
  Button,
  Flex,
  Header,
  GoBackButton, CheckoutBlock,
} from '@components';
import { routes } from '@utils';
import { STORE_NAMES } from '@constants';

import { getAllSubscriptions, resetUserActiveOrderState, resetUserPaymentFlowState } from 'src/store/products/duck';
import { trackGTMViewItem } from '@utils/helpers';
import { SubscriptionBlock } from './components';
import { formatSubscriptionTitle, getSubscription } from '../../utils/helpers';

const Subscription = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [isMobile, isDesktop] = useWindowSize();

  const {
    authorization_info: {
      id: customerId,
      email: customerEmail,
    },
  } = useSelector(store => store[STORE_NAMES.AUTH]);
  const {
    availableProducts: {
      subscriptions,
    },
  } = useSelector(store => store[STORE_NAMES.PRODUCTS]);

  const [selectedSubscription, setSelectedSubscription] = useState({});

  const isViewItemTracked = useRef(false);

  useEffect(() => {
    if (selectedSubscription?.id && !isViewItemTracked.current) {
      trackGTMViewItem({
        subscription: selectedSubscription,
        customer: { id: customerId, email: customerEmail },
      });

      isViewItemTracked.current = true;
    }
  }, [selectedSubscription]);

  useEffect(() => {
    if (subscriptions.length === 0) {
      dispatch(getAllSubscriptions());
    }
  }, []);

  useEffect(async () => {
    if (subscriptions.length === 0) {
      await dispatch(getAllSubscriptions());
    } else {
      const foundSubscription = getSubscription(id, subscriptions);

      if (!foundSubscription) {
        history.push(routes.notFound);
      }

      setSelectedSubscription(foundSubscription);
    }
  }, [subscriptions]);

  useEffect(() => () => {
    // reset state when leave this screen
    dispatch(resetUserPaymentFlowState());
    dispatch(resetUserActiveOrderState());
  }, []);

  useEffect(() => {
    if (!selectedSubscription) return;

    document.title = formatSubscriptionTitle(selectedSubscription, i18n.language);
  }, [selectedSubscription]);

  const handleRedirectToCheckout = () => {
    history.push(`${routes.checkout}/${id}`);
  };

  const handleRedirectToSubscriptions = () => {
    history.push(routes.subscriptions);
  };

  return (
    <Flex column width="100%" height="100%">
      {isDesktop && <Header />}
      <Flex
        paddingLeft={isMobile ? 'xl' : 'xl10'}
        paddingRight={isMobile ? 'xl' : 'xl10'}
        justifyCenter
        width="100%"
        height="100%"
      >
        <Flex column marginTop="56px" maxWidth={isDesktop ? '1440px' : '600px'} width="100%">
          <GoBackButton
            onRedirect={handleRedirectToSubscriptions}
            styles={{ marginBottom: 'xl7' }}
            title={isDesktop ? t('GetSubscription.Title') : t('GetSubscription.SubscriptionInfo')}
          />
          <Flex column={!isDesktop} paddingBottom="50px" width="100%" height="100%" justifyContent="space-between">
            <SubscriptionBlock subscription={selectedSubscription} />
            {
              isDesktop ? (
                <CheckoutBlock
                  subscription={selectedSubscription}
                />
              ) : (
                <Button
                  width="100%"
                  minHeight="48px"
                  height="48px"
                  marginTop="auto"
                  bgColor="green"
                  flexShink={0}
                  onClick={handleRedirectToCheckout}
                >
                  {t('SubscriptionsInfo.Button')}
                </Button>
              )
            }
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Subscription;
