import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  Flex, Typography, Icon, InputField, ButtonWithLoader, LanguageSwitcher,
} from '@components';
import { useWindowSize } from '@hooks';
import { routes } from '@utils';
import { showErrorToast } from '@utils/helpers';
import { resetPassword } from '@store';
import { EMAIL_REGEX, STORE_NAMES } from '@constants';

import { ReactComponent as ArrowBack } from '@assets/ArrowBack.svg';

import { PreviewImage } from './components';

const ResetPasswordScreen = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isMobile, isDesktop] = useWindowSize();

  const [email, setEmail] = useState();
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);

  const { isPending } = useSelector(store => store[STORE_NAMES.AUTH]);

  const handleGoBack = () => {
    history.goBack();
  };

  const handleChangeEmail = e => {
    const { value } = e.target;

    setEmail(value);
  };

  const handleSubmit = async () => {
    if (!EMAIL_REGEX.test(email)) {
      setIsInvalidEmail(true);
      return;
    }
    const result = await dispatch(resetPassword({ email }));
    if (result.error) {
      showErrorToast(result.payload.data.error.message);
    } else {
      history.push({
        pathname: routes.verifyEmail,
        state: { isVerifyEmail: false },
      });
    }
  };

  return (
    <Flex minHeight="100vh">
      {isDesktop && <PreviewImage />}
      <Flex justifyCenter>
        <Flex column maxWidth={isMobile ? '80%' : '55%'} marginTop="140px" position="relative">
          {isDesktop ? (
            <>
              <Flex
                grow="0"
                position="absolute"
                left="0"
                top="135px"
                cursor="pointer"
                onClick={handleGoBack}
              >
                <Icon SVG={ArrowBack} />
              </Flex>
              <Flex justifyCenter marginBottom="80px" grow="0">
                <LanguageSwitcher />
              </Flex>
            </>
          ) : (
            <Flex
              grow="0"
              marginBottom="xl7"
              cursor="pointer"
              onClick={handleGoBack}
            >
              <Icon SVG={ArrowBack} />
            </Flex>
          )}
          <Typography
            color="dark"
            size="xl4"
            weight="bold"
            textAlign="center"
            marginBottom="xl"
          >
            {t('ResetPasswordScreen.Title')}
          </Typography>
          <Typography
            textAlign="center"
            color="darkGrey"
            marginBottom="xl7"
          >
            {t('ResetPasswordScreen.Subtitle')}
          </Typography>
          <InputField
            label={t('ResetPasswordScreen.Email')}
            value={email}
            onChange={handleChangeEmail}
            isError={isInvalidEmail}
          />
          <Flex marginTop="xl4">
            <ButtonWithLoader
              onClick={handleSubmit}
              text={t('ResetPasswordScreen.Send')}
              isLoading={isPending}
            />
          </Flex>
          {!isDesktop && (
            <Flex justifyCenter marginTop="xl6">
              <LanguageSwitcher />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ResetPasswordScreen;
