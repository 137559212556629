import React, { useState } from 'react';

import { Flex } from '@components';
import { arrayOf, number, shape } from 'prop-types';
import { useWindowSize } from '@hooks';
import TabNavItem from '../TabNavItem/TabNavItem';
import TabContent from '../TabContent/TabContent';

const TabsContainer = ({
  tabs, activeTabId, mainContainerStyles, tabItemsContainerStyles,
}) => {
  const [activeTab, setActiveTab] = useState(activeTabId || 1);
  const [, isDesktop] = useWindowSize();

  return (
    <Flex
      column
      marginTop="56px"
      maxWidth={isDesktop ? '1440px' : '600px'}
      itemsCenter={!isDesktop}
      {...mainContainerStyles}
    >
      <Flex
        width={isDesktop ? '400px' : '100%'}
        {...tabItemsContainerStyles}
        height="48px"
        grow={0}
      >
        {tabs.map(({
          title, id, disabled, tooltipText,
        }) => (
          <TabNavItem
            key={id}
            title={title}
            id={id}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            disabled={disabled}
            tooltipText={tooltipText}
          />
        ))}
      </Flex>
      <Flex width="100%" height="100%">
        {tabs.map(({ id, component }) => <TabContent key={id} id={id} activeTab={activeTab}>{component}</TabContent>)}
      </Flex>
    </Flex>
  );
};

TabsContainer.propTypes = {
  tabs: arrayOf(shape({})).isRequired,
  mainContainerStyles: shape({}),
  tabItemsContainerStyles: shape({}),
  activeTabId: number,
};

TabsContainer.defaultProps = {
  activeTabId: 1,
  mainContainerStyles: {},
  tabItemsContainerStyles: {},
};

export default TabsContainer;
