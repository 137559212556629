const color = {
  black: '#000000',
  dark: '#04162d',
  darkSecondary: '#00162f',
  white: '#ffffff',
  green: '#1bc98e',
  greenHover: 'linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #1bc98e;',
  greenActive: 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #1bc98e;',
  greenDisabled: '#adecd6',
  lightGreen: '#E8F9F3',
  grey: '#999999',
  greyApple: '#6b7888',
  lightGrey: '#e4e4ea',
  darkGrey: '#6a7587',
  greySecondary: '#a8aeB5',
  sunsetOrange: '#ff4744',
  platinum: '#e7e7e7',
  toast: 'rgba(0, 22, 47, 0.7)',
  redDark: '#F94642',
  blueDark: '#6D88F8',
};

const theme = {
  border: {
    radius: {
      circular: '50%',
      xs: '4px',
      sm: '12px',
      md: '40px',
      default: '100px',
      searchBar: '400px',
    },
  },
  color,
  boxShadow: {
    default: '0px 3px 8px rgba(0, 22, 47, 0.04)',
    light: '0px 0px 16px rgba(0, 22, 47, 0.08)',
  },
  spacing: {
    none: '0px',
    xxxs: '2px',
    xxs: '4px',
    xs: '6px',
    sm: '8px',
    md: '12px',
    lg: '14px',
    xl: '16px',
    xl2: '18px',
    xl3: '20px',
    xl4: '24px',
    xl5: '28px',
    xl6: '32px',
    xl7: '40px',
    xl8: '48px',
    xl9: '64px',
    xl10: '72px',
  },
  typography: {
    family: 'Poppins, sans-serif',
    size: {
      xxs: '8px',
      xs: '10px',
      sm: '12px',
      md: '14px',
      lg: '16px',
      xl: '18px',
      xl2: '20px',
      xl3: '22px',
      xl4: '24px',
      xl5: '32px',
      xl6: '48px',
      xxl: '120px',
      xxxl: '180px',
    },
    weight: {
      regular: 400,
      medium: 500,
      bold: 700,
    },
  },
};

export default theme;

