import React, { useState } from 'react';
import { string } from 'prop-types';

import { Flex, Icon, Typography } from '@components';
import { ReactComponent as TooltipIcon } from '@assets/TooltipIcon.svg';
import styled from 'styled-components';

const Tooltip = ({ tooltipText, width, position }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenToggle = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <Flex grow="0" position="relative" shadow="light">
      <Flex grow="0" onMouseOver={handleOpenToggle} onMouseOut={handleOpenToggle}>
        <Icon SVG={TooltipIcon} width="16px" height="16px" />
      </Flex>
      {isOpen && (
        <Wrapper
          position="absolute"
          left={position === 'right' ? '36px' : 'unset'}
          top="50%"
          right={position === 'right' ? 'unset' : '36px'}
          zIndex="2"
          bgColor="white"
          shadow="light"
          borderRadius="sm"
          width={width}
        >
          <Typography color="darkSecondary" padding="md">{tooltipText}</Typography>
        </Wrapper>
      )}
    </Flex>
  );
};

const Wrapper = styled(Flex)`
  transform: translateY(-50%);
`;

Tooltip.propTypes = {
  tooltipText: string.isRequired,
  width: string,
  position: string,
};

Tooltip.defaultProps = {
  width: 'max-content',
  position: 'right',
};

export default Tooltip;
