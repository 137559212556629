import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLIC_KEY } from '../constants/common';

const stripe = loadStripe(STRIPE_PUBLIC_KEY, {
  apiVersion: '2020-08-27',
});

const stripeOptions = {
  fonts: [{
    family: 'Poppins',
    src: 'url(/fonts/Poppins-Regular.ttf)',
    weight: 400,
  }],
};

export default {
  stripe,
  stripeOptions,
};
