import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Flex } from '@components';
import { func } from 'prop-types';
import { STORE_NAMES } from '@constants';
import { STRIPE_PRODUCT_TYPES, TRACKING_PRODUCT_TYPES } from 'src/constants/common';

import { Filters, ProductsList } from './components';

const ProductsTab = ({ trackList }) => {
  const {
    availableProducts: { non_consumable_products, consumable_products },
  } = useSelector(store => store[STORE_NAMES.PRODUCTS]);
  const { authorization_info: { id: customerId } } = useSelector(store => store[STORE_NAMES.AUTH]);

  const [products, setProducts] = useState([]);
  const isListTracked = useRef(false);

  useEffect(() => {
    if (!isListTracked.current) {
      trackList({
        products: [
          ...non_consumable_products.map(product => ({
            ...product,
            stripe_product_type: STRIPE_PRODUCT_TYPES.NON_CONSUMABLE,
          })),
          ...consumable_products.map(product => ({
            ...product,
            stripe_product_type: STRIPE_PRODUCT_TYPES.CONSUMABLE,
          })),
        ],
        customerId,
        type: TRACKING_PRODUCT_TYPES.product,
      });
      isListTracked.current = true;
    }

    setProducts([...non_consumable_products, ...consumable_products]);
  }, [non_consumable_products, consumable_products]);

  return (
    <Wrapper column maxWidth="730px">
      <Filters products={products} setProducts={setProducts} />
      <ProductsList products={products} />
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  @media (max-width: 1024px) {
    width: 600px;
  }

  @media (max-width: 700px) {
    width: 500px;
  }

  @media (max-width: 600px) {
    width: 400px;
  }

  @media (max-width: 450px) {
    width: 300px;
  }
`;

ProductsTab.propTypes = {
  trackList: func.isRequired,
};

export default ProductsTab;
