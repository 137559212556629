import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Flex, Header, HeaderMobile } from '@components';
import { useWindowSize } from '@hooks';
import { getUserPurchases } from '@store';

import { PurchasesDesktop, PurchasesMobile } from './pages';

const UserPurchases = () => {
  const dispatch = useDispatch();

  const [, isDesktop] = useWindowSize();

  useEffect(() => {
    dispatch(getUserPurchases());
  }, []);

  return (
    <Flex column minHeight="100vh">
      {isDesktop ? <Header /> : <HeaderMobile />}
      <Flex
        paddingLeft="xl10"
        paddingRight="xl10"
        justifyCenter
        grow="0"
      >
        {isDesktop ? <PurchasesDesktop /> : <PurchasesMobile />}
      </Flex>
    </Flex>
  );
};

export default UserPurchases;
