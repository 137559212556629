import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { GOOGLE_APP_ID } from '@constants/common';
import {
  Flex, Button, Icon, Typography,
} from '@components';
import { ReactComponent as GoogleIcon } from '@assets/GoogleIcon.svg';

const GOOGLE_LOGIN_OPTIONS = {
  clientId: GOOGLE_APP_ID,
  button: { shape: 'circle', size: 'large' },
};

const GoogleLoginButton = ({ googleSubmit, isAgreedWithPolicy }) => {
  const { t } = useTranslation();
  const [google, setGoogle] = useState();
  const googleButtonRef = useRef(null);
  const isAgreedWithPolicyRef = useRef(isAgreedWithPolicy);

  useEffect(() => {
    if (!window?.google) return;

    setGoogle(window.google);
  }, [window?.google]);

  useEffect(() => {
    isAgreedWithPolicyRef.current = isAgreedWithPolicy;
  }, [isAgreedWithPolicy]);

  const handleResponseCallback = response => {
    googleSubmit(response, isAgreedWithPolicyRef.current);
  };

  useEffect(() => {
    if (google) {
      google.accounts.id.initialize({
        client_id: GOOGLE_LOGIN_OPTIONS.clientId,
        callback: handleResponseCallback,
      });
      google.accounts.id.renderButton(
        googleButtonRef.current,
        GOOGLE_LOGIN_OPTIONS.button,
      );
    }
  }, [google]);

  return (
    <ButtonsContainer grow="0">
      <GoogleButton ref={googleButtonRef} className="google-btn" />
      <CustomGoogleButton
        bgColor="white"
        width="100px"
      >
        <Flex justifyCenter itemsCenter height="36px">
          <Icon SVG={GoogleIcon} />
          <Typography marginLeft="md" color="darkSecondary" size="lg">{t('AuthCommonContent.Google')}</Typography>
        </Flex>
      </CustomGoogleButton>
    </ButtonsContainer>
  );
};

const ButtonsContainer = styled(Flex)`
  ${({ theme: { color: colorFromTheme } }) => css`
    position: relative;

    @media (max-width: 600px) {
      margin-bottom: 4px;
    }

    @media (min-width: 1024px) and (max-width: 1390px) {
      margin-bottom: 4px;
    }

    .google-btn:hover + button {
      background: ${colorFromTheme.greenHover};
    }

    .google-btn:active + button {
      background: ${colorFromTheme.greenActice};
    }
  `}
`;

const GoogleButton = styled.div`
  height: 36px;
  opacity: 0;
`;

const CustomGoogleButton = styled(Button)`
  position: absolute;
  width: 100%;
  max-width: 400px;
  z-index: -1;
`;

GoogleLoginButton.propTypes = {
  googleSubmit: func,
  isAgreedWithPolicy: bool.isRequired,
};

GoogleLoginButton.defaultProps = {
  googleSubmit: () => {},
};

export default GoogleLoginButton;
