import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Flex } from '@components';
import { func } from 'prop-types';
import { STORE_NAMES } from '@constants';
import { STRIPE_PRODUCT_TYPES, TRACKING_PRODUCT_TYPES } from '@constants/common';

import { SubscriptionItem } from './components';

const SubscriptionsTabMobile = ({ trackList }) => {
  const { availableProducts: { subscriptions } } = useSelector(store => store[STORE_NAMES.PRODUCTS]);
  const { authorization_info: { id: customerId } } = useSelector(store => store[STORE_NAMES.AUTH]);

  const isListTracked = useRef(false);

  useEffect(() => {
    if (subscriptions.length !== 0 && !isListTracked.current) {
      trackList({
        products: subscriptions.map(sub => ({
          ...sub,
          stripe_product_type: STRIPE_PRODUCT_TYPES.SUBSCRIPTION,
        })),
        customerId,
        type: TRACKING_PRODUCT_TYPES.subscription,
      });
      isListTracked.current = true;
    }
  }, [subscriptions]);

  return (
    <Flex column marginTop="44px" marginBottom="xl9">
      <StyledSlider
        className="subscriptions__slider--mobile"
        arrows={false}
        swipeToSlide
        dots
      >
        {subscriptions.map(subscription => (
          <SubscriptionItem key={subscription.id} subscription={subscription} />
        ))}
      </StyledSlider>
    </Flex>
  );
};

const StyledSlider = styled(Slider)`
    width: 500px;
    @media (max-width: 600px) {
      width: 400px;
    }

    @media (max-width: 500px) {
      width: 380px;
    }

    @media (max-width: 450px) {
      width: 350px;
    }

    @media (max-width: 380px) {
      width: 300px;
    }

    .slick-track {
      display: flex;
      align-items: flex-end;
    }

    .borderedBottom {
      border-bottom: 1px solid rgba(107, 120, 136, 0.1);
    }

    .slick-dots {
      top: -30px;
      bottom: initial;
    }
`;

SubscriptionsTabMobile.propTypes = {
  trackList: func.isRequired,
};

export default SubscriptionsTabMobile;
