import { useState, useEffect } from 'react';
import { MOBILE_WIDTH, TABLET_WIDTH } from '@constants/common';

const useWindowSize = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isDesktop = windowWidth > TABLET_WIDTH;
  const isMobile = windowWidth < MOBILE_WIDTH;

  return [isMobile, isDesktop];
};

export default useWindowSize;
