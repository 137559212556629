import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, TabsContainer } from '@components';
import { UserProductsTab, UserSubscriptionTab } from './tabs';

const PurchasesMobile = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      id: 1,
      title: t('Purchases.MySubscription'),
      component: <UserSubscriptionTab />,
    },
    {
      id: 2,
      title: t('Purchases.MyProducts'),
      component: <UserProductsTab />,
    },
  ];

  return (
    <Flex justifyCenter>
      <TabsContainer tabs={tabs} />
    </Flex>
  );
};

export default PurchasesMobile;
