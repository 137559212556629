import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  #root, body, html {
    height: 100%;
    width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
  };
  
  #modal-root {
    height: 100vh;
    width: 100%;
    background-color: rgba(52, 64, 84, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 100;
    overflow: hidden;
  };
`;

export default GlobalStyle;
