import { useEffect, useRef } from 'react';

const useOutsideClickHandler = callback => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target) && callback) {
        callback();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [callback]);

  return ref;
};

export default useOutsideClickHandler;
