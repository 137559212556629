export const EMAIL_FORM_VARIANTS = {
  DEFAULT: 'default',
  NOT_REGISTERED: 'notRegistered',
  REGISTERED: 'registered',
};

export const layoutByVariant = {
  [EMAIL_FORM_VARIANTS.DEFAULT]: {
    title: 'EmailForm.Default.Title',
    description: 'EmailForm.Default.Description',
    helperText: 'EmailForm.Default.HelperText',
  },
  [EMAIL_FORM_VARIANTS.NOT_REGISTERED]: {
    title: 'EmailForm.NotRegistered.Title',
    description: 'EmailForm.NotRegistered.Description',
    helperText: 'EmailForm.NotRegistered.HelperText',
  },
  [EMAIL_FORM_VARIANTS.REGISTERED]: {
    title: 'EmailForm.Registered.Title',
    description: 'EmailForm.Registered.Description',
    helperText: '',
  },
};
