import styled, { css } from 'styled-components';
import { marginMixin, paddingMixin } from '@mixins';

const {
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
} = marginMixin;

const {
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
} = paddingMixin;

const Typography = styled.p`
  ${({
    color,
    size,
    weight,
    textAlign,
    display,
    lineHeight,
    cursor,
    nowrap,
    uppercase,
    fadinAnimation,
    width,
    opacity,
    maxWidth,
    theme: {
      color: colorFromTheme,
      typography,
    },
  }) => css`
    ${margin}
    ${marginTop}
    ${marginRight}
    ${marginBottom}
    ${marginLeft}

    ${padding}
    ${paddingTop}
    ${paddingRight}
    ${paddingBottom}
    ${paddingLeft}

    ${display && `
      display: ${display};
    `}

    ${textAlign && `
      text-align: ${textAlign};
    `}

    ${color && `
      color: ${colorFromTheme[color]};
    `}

    ${size && `
      font-size: ${typography.size[size]};
    `}

    ${weight && `
      font-weight: ${typography.weight[weight]};
    `}

    ${cursor && `
      cursor: ${cursor};
    `}

    ${nowrap && `
      white-space: nowrap;  
    `}

    ${uppercase && `
      text-transform: uppercase;  
    `}

    ${width && `
      width: ${width};  
    `}
    
    ${maxWidth && `
      max-width: ${maxWidth};  
    `}    
    
    ${opacity && `
      opacity: ${opacity};  
    `}

    ${fadinAnimation && `
      @keyframes fadIn {
        0% { opacity: 0%; }
        50% { opacity: 0%; }
        100% { opacity: 100%; }
      }

      animation: fadIn 1.5s;
    `}

    font-family: ${typography.family};
    line-height: ${lineHeight || 'normal'};
  `}
`;

export default Typography;
