import styled, { css } from 'styled-components';
import Flex from '../Flex';

export const Container = styled(Flex)`
  ${() => css`
    flex-direction: column;
    width: 100%;
    padding-top: 16px;
    gap: 16px;
  `}
`;

export const ChipsContainer = styled.div`
  ${({ isMobile }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
    
    ${isMobile && css`
      width: calc(100vw - 17px);
      display: flex;
      overflow-x: scroll;
    `}
  `}
`;
