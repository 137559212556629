import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { Flex, Typography } from '@components';
import { STORE_NAMES } from '@constants';

const UserProductsTab = () => {
  const { t, i18n } = useTranslation();

  // TODO: recheck what we have in response from backend and do changes accordint to it here and in other places
  // (maybe we don't have concumable or non_consumable, but have just products)
  const { userPurchases: { non_consumable_products } } = useSelector(store => store[STORE_NAMES.PRODUCTS]);

  return (
    <Flex column justifyCenter marginTop="xl4">
      {non_consumable_products?.length ? (
        non_consumable_products.map(product => {
          const productLink = i18n.language === 'no' ? product.link_no : product.link_eng;
          return (
            <BorderedWrapper key={product.id} itemsCenter padding="xl4">
              <Flex column>
                <Typography size="md" color="greyApple">
                  {product.type === 'booklet' ? 'Booklet' : 'E-book'}
                </Typography>
                <Typography size="xl3" color="darkSecondary">{product.name}</Typography>
              </Flex>
              <DownloadLink href={productLink} download target="_blank">
                {t('Purchases.Download')}
              </DownloadLink>
            </BorderedWrapper>
          );
        })
      ) : (
        <Typography color="darkSecondary">{t('Purchases.ProductsPlaceholder')}</Typography>
      )}
    </Flex>
  );
};

const BorderedWrapper = styled(Flex)`
    &:not(:last-child) {
        border-bottom: 1px solid rgba(107, 120, 136, 0.1);
    }

    @media (max-width: 1024px) {
        width: 600px;
    }

    @media (max-width: 700px) {
      width: 500px;
    }

    @media (max-width: 600px) {
      width: 400px;
    }

    @media (max-width: 450px) {
      width: 300px;
    }
`;

const DownloadLink = styled.a`
    ${({ theme: { typography } }) => css`
    font-family: ${typography.family};
    font-size: 14px;
    color: #1bc98e;
    text-decoration: none;
  `}
`;

export default UserProductsTab;
