import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  bool, number, shape, string,
} from 'prop-types';

import {
  Button, Flex, Icon, Typography,
} from '@components';
import { routes } from '@utils';
import { clearGtmDataLayer, removePrefix } from '@utils/helpers';
import { STORE_NAMES } from '@constants';

import { ReactComponent as CheckMarkGreen } from '@assets/CheckMarkGreen.svg';
import { ReactComponent as CrossMarkGrey } from '@assets/CrossMarkGrey.svg';

const SubscriptionItem = ({ subscription }) => {
  const {
    id,
    title,
    title_no,
    price,
    duration,
    has_coaching,
    has_training_program,
    has_own_training_program,
    has_diet_plan,
    has_recipes_db,
  } = subscription;
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const { authorization_info } = useSelector(store => store[STORE_NAMES.AUTH]);

  const trackLead = () => {
    clearGtmDataLayer();
    window.dataLayer.push({
      event: 'lead',
      meta: {
        email: authorization_info.email,
        eventProps: {
          content_category: 'fitness',
          content_name: subscription.title,
          currency: 'NOK',
          value: subscription.price / 100,
        },
      },
      userProps: {
        user_id: authorization_info.id,
        subscription_id: subscription.id,
      },
    });
  };

  const handleGetSubscription = () => {
    trackLead();
    history.push(`${routes.subscriptions}/${id}`);
  };

  return (
    <Flex column>
      <Flex
        shadow="light"
        column
        borderRadius="sm"
        bgColor="white"
        marginBottom="xl8"
        marginRight="md"
        marginLeft="md"
        marginTop="md"
      >
        <Flex column padding="xl4" className="borderedBottom">
          <Typography
            size="md"
            color="greyApple"
          >
            {`${duration} ${t(`Subscriptions.${duration > 1 ? 'Months' : 'Month'}`)}`}
          </Typography>
          <Typography size="xl" color="darkSecondary">
            {
              i18n.language === 'en'
                ? removePrefix(title, 'Secondary')
                : removePrefix(title_no, 'Secondary')
            }
          </Typography>
          <Flex paddingBottom="xl4">
            <Flex grow="0" column marginRight="xl6">
              <Typography weight="bold" size="xl2" color="darkSecondary">{`${price / 100} kr`}</Typography>
              <Typography color="greyApple" size="md">{t('Subscriptions.FirstPayment')}</Typography>
            </Flex>
            {/* <Flex grow="0" column>
              <Typography
                weight="bold"
                size="xl2"
                color="darkSecondary"
              >
                {`${price_after_first_payment / 100} kr`}
              </Typography>
              <Typography color="greyApple" size="md">{t('Subscriptions.NextPayment')}</Typography>
            </Flex> */}
          </Flex>
        </Flex>
        <Flex column padding="xl4">
          <Flex column marginTop="xl4" marginBottom="xl4">
            <Flex marginBottom="xxs">
              <Icon SVG={has_coaching ? CheckMarkGreen : CrossMarkGrey} width="20px" height="20px" />
              <Typography
                color="darkSecondary"
                marginLeft="md"
              >
                {t('Subscriptions.TableHeaders.Coaching')}
              </Typography>
            </Flex>
          </Flex>
          <Flex marginBottom="xl4">
            <Icon SVG={has_training_program ? CheckMarkGreen : CrossMarkGrey} width="20px" height="20px" />
            <Typography
              color="darkSecondary"
              marginLeft="md"
            >
              {t('Subscriptions.TableHeaders.TrainingProgram')}
            </Typography>
          </Flex>
          <Flex marginBottom="xl4">
            <Icon
              SVG={has_own_training_program ? CheckMarkGreen : CrossMarkGrey}
              width="20px"
              height="20px"
            />
            <Typography
              color="darkSecondary"
              marginLeft="md"
            >
              {t('Subscriptions.TableHeaders.Workout')}
            </Typography>
          </Flex>
          <Flex marginBottom="xl4">
            <Icon SVG={has_diet_plan ? CheckMarkGreen : CrossMarkGrey} width="20px" height="20px" />
            <Typography
              color="darkSecondary"
              marginLeft="md"
            >
              {t('Subscriptions.TableHeaders.DietPlan')}
            </Typography>
          </Flex>
          <Flex marginBottom="xl4">
            <Icon SVG={has_recipes_db ? CheckMarkGreen : CrossMarkGrey} width="20px" height="20px" />
            <Typography
              color="darkSecondary"
              marginLeft="md"
            >
              {t('Subscriptions.TableHeaders.Recipes')}
            </Typography>
          </Flex>
          {/* <Flex marginBottom="xl4">
            <Icon SVG={has_booklets ? CheckMarkGreen : CrossMarkGrey} width="20px" height="20px" />
            <Typography
              color="darkSecondary"
              marginLeft="md"
            >
              {t('Subscriptions.TableHeaders.Booklets')}
            </Typography>
          </Flex> */}
        </Flex>
      </Flex>
      <Button
        fullWidth
        bgColor="green"
        size="16px"
        height="48px"
        onClick={handleGetSubscription}
      >
        {t('Subscriptions.GetButton')}
      </Button>
    </Flex>
  );
};

SubscriptionItem.propTypes = {
  subscription: shape({
    id: number.isRequired,
    price: number.isRequired,
    price_after_first_payment: number.isRequired,
    duration: number.isRequired,
    title: string.isRequired,
    title_no: string.isRequired,
    has_chat: bool.isRequired,
    has_diet_plan: bool.isRequired,
    has_training_program: bool.isRequired,
    has_own_training_program: bool.isRequired,
    has_coaching: bool.isRequired,
    has_booklets: bool.isRequired,
    has_recipes_db: bool.isRequired,
  }).isRequired,
};

export default SubscriptionItem;
