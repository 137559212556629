/* eslint-disable consistent-return */
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  INITIAL_STATE,
  PENDING_STATE,
  REJECTED_STATE,
  FULFILLED_STATE,
  STORE_NAMES,
} from '@constants';

import { createCommonAsyncThunk } from '@utils/helpers';
import { clearToken, clearLocalStorage } from '@utils/storage';
import api from './api';

const { AUTH } = STORE_NAMES;

const initialState = {
  ...INITIAL_STATE,
  userLanguage: 'no',
  authorization_info: {},
};

const loginThunkName = `${AUTH}/login`;
const registerThunkName = `${AUTH}/register`;
const verifyEmailThunkName = `${AUTH}/verifyEmail`;
const resetPasswordThunkName = `${AUTH}/resetPassword`;
const verifyResetPasswordThunkName = `${AUTH}/verifyResetPassword`;
const changePasswordThunkName = `${AUTH}/changePassword`;
const logoutThunkName = `${AUTH}/logout`;
const deleteAccountThunkName = `${AUTH}/deleteAccount`;
const verifyChangedEmailThunkName = `${AUTH}/verifyChangedEmail`;

export const login = createCommonAsyncThunk(
  loginThunkName,
  api.login,
);

export const register = createCommonAsyncThunk(
  registerThunkName,
  api.register,
);

export const verifyEmail = createCommonAsyncThunk(
  verifyEmailThunkName,
  api.verifyEmail,
);

export const resetPassword = createCommonAsyncThunk(
  resetPasswordThunkName,
  api.resetPassword,
);

export const verifyResetPassword = createCommonAsyncThunk(
  verifyResetPasswordThunkName,
  api.verifyResetPassword,
);

export const changePassword = createCommonAsyncThunk(
  changePasswordThunkName,
  api.changePassword,
);

export const logout = createCommonAsyncThunk(
  logoutThunkName,
  api.logout,
);

export const deleteAccount = createCommonAsyncThunk(
  deleteAccountThunkName,
  api.deleteAccount,
);

export const verifyChangedEmail = createCommonAsyncThunk(
  verifyChangedEmailThunkName,
  api.verifyChangedEmail,
);

const authorizationSlice = createSlice({
  name: AUTH,
  initialState,
  reducers: {
    resetState: () => initialState,
    resetError: state => ({
      ...state,
      error: false,
    }),
  },
  extraReducers: builder => {
    builder
      .addCase(register.fulfilled, (state, action) => {
        const { email } = action.payload;
        return ({
          ...state,
          ...FULFILLED_STATE,
          authorization_info: {
            ...state.authorization_info,
            email,
          },
        });
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        const { token } = action.payload;
        return ({
          ...state,
          ...FULFILLED_STATE,
          authorization_info: {
            ...state.authorization_info,
            token,
          },
        });
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        const { email } = action.payload;
        return ({
          ...state,
          ...FULFILLED_STATE,
          authorization_info: {
            ...state.authorization_info,
            email,
          },
        });
      })
      .addCase(verifyResetPassword.fulfilled, (state, action) => {
        const { reset_password_token } = action.payload;
        return ({
          ...state,
          ...FULFILLED_STATE,
          authorization_info: {
            ...state.authorization_info,
            reset_password_token,
          },
        });
      })
      .addCase(changePassword.fulfilled, state => ({
        ...state,
        ...FULFILLED_STATE,
      }))
      .addCase(login.fulfilled, (state, action) => {
        const {
          id, email, token, fullname, has_subscription, signup_date,
        } = action.payload;
        return ({
          ...state,
          ...FULFILLED_STATE,
          authorization_info: {
            ...state.authorization_info,
            id,
            email,
            token,
            fullname,
            has_subscription,
            signup_date,
          },
        });
      })
      .addCase(logout.fulfilled, () => {
        clearToken();
        return ({
          ...initialState,
          ...FULFILLED_STATE,
        });
      })
      .addCase(deleteAccount.fulfilled, state => {
        clearLocalStorage();

        return ({
          ...state,
          ...FULFILLED_STATE,
        });
      })
      .addCase(verifyChangedEmail.fulfilled, state => ({
        ...state,
        ...FULFILLED_STATE,
      }))
      .addMatcher(isAnyOf(
        register.pending,
        login.pending,
        verifyEmail.pending,
        resetPassword.pending,
        verifyResetPassword.pending,
        changePassword.pending,
        deleteAccount.pending,
        verifyChangedEmail.pending,
      ), state => {
        if (!state.isPending) {
          return {
            ...state,
            ...PENDING_STATE,
          };
        }
      })
      .addMatcher(
        isAnyOf(
          register.rejected,
          login.rejected,
          verifyEmail.rejected,
          resetPassword.rejected,
          verifyResetPassword.rejected,
          changePassword.rejected,
          deleteAccount.rejected,
          verifyChangedEmail.rejected,
        ),
        (state, action) => {
          const {
            data: { message, errors },
            status,
          } = action.payload;

          if (state.isPending) {
            const newState = {
              ...state,
              ...REJECTED_STATE,
              error: { message, status, errors },
            };

            return newState;
          }
        },
      );
  },
});

const authorizationPersistConfig = {
  key: AUTH,
  storage: AsyncStorage,
};

const { actions: authorizationActions, reducer: authorizationReducer } = authorizationSlice;

export { authorizationActions, authorizationReducer, authorizationPersistConfig };
