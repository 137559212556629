import { Flex, Typography } from '@components';
import React from 'react';
import { number } from 'prop-types';
import { formatSubscriptionPrice } from '@utils/helpers';
import { useTranslation } from 'react-i18next';

const PaymentsInfo = ({ firstPayment, nextPayment }) => {
  const { t } = useTranslation();
  return (
    <Flex paddingBottom="xl4" width="100%">
      <Flex
        column
        marginRight="xl6"
        width="60%"
        bgColor="lightGreen"
        padding="16px 24px"
        borderRadius="16px"
        marginTop="16px"
        itemsCenter
      >
        <Typography weight="bold" size="xl5" color="green">{formatSubscriptionPrice(firstPayment)}</Typography>
        <Typography color="greyApple" size="md">{t('Subscriptions.FirstPayment')}</Typography>
      </Flex>
      <Flex grow="0" column padding="16px 24px" itemsCenter justifyCenter>
        <Typography
          size="xl4"
          color="darkSecondary"
        >
          {formatSubscriptionPrice(nextPayment)}
        </Typography>
        <Typography color="greyApple" size="md">{t('Subscriptions.NextPayment')}</Typography>
      </Flex>
    </Flex>
  );
};

PaymentsInfo.propTypes = {
  firstPayment: number.isRequired,
  nextPayment: number.isRequired,
};

export default PaymentsInfo;
