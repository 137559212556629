const routes = {
  root: '/',
  logIn: '/log-in',
  signUp: '/sign-up',
  verifyEmail: '/verify-email',
  resetPassword: '/resset-password',
  changePassword: '/change-password',
  subscriptions: '/subscriptions',
  checkout: '/checkout',
  products: '/products',
  purchases: '/purchases',
  notFound: '/404',
  success: '/success',
  changeEmailVerification: '/change-email-verification',
};

export default routes;
