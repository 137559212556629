import React from 'react';
import {
  BackgroundImage, Flex, Typography, Icon,
} from '@components';
import GetInShapeMainImage from '@assets/GetInShapeMainImage.png';
import { ReactComponent as GetInShapeText } from '@assets/GetInShapeText.svg';
import { useTranslation } from 'react-i18next';

const PreviewImage = () => {
  const { t } = useTranslation();

  return (
    <BackgroundImage
      justifyCenter
      position="relative"
      minWidth="50%"
      imageSrc={GetInShapeMainImage}
    >
      <Flex
        column
        position="absolute"
        bottom="172px"
        maxWidth="475px"
        paddingLeft="md"
        paddingRight="md"
      >
        <Icon SVG={GetInShapeText} height="56px" width="300px" />
        <Typography color="white" size="xl4" paddingTop="xl4">
          {t('AuthCommonContent.AuthorizationText')}
        </Typography>
      </Flex>
    </BackgroundImage>
  );
};

export default PreviewImage;
