import React from 'react';
import { bool, func, string } from 'prop-types';

import {
  Flex, Button, Typography, Spinner,
} from '@components';
import styled from 'styled-components';

const ButtonWithLoader = ({
  text, onClick, isLoading, width, height, bgColor, disabled, minHeight, marginTop, className,
}) => (
  <Button
    onClick={onClick}
    width={width}
    height={height}
    marginTop={marginTop}
    minHeight={minHeight}
    bgColor={bgColor}
    disabled={disabled || isLoading}
    className={className}
  >
    <Flex itemsCenter justifyCenter position="relative">
      <Typography size="lg">{text}</Typography>
      <PositionedSpinner isLoading={isLoading} />
    </Flex>
  </Button>
);

const PositionedSpinner = styled(Spinner)`
    position: absolute;
    right: 20px;
`;

ButtonWithLoader.propTypes = {
  text: string.isRequired,
  onClick: func,
  isLoading: bool,
  width: string,
  height: string,
  bgColor: string,
  disabled: bool,
  minHeight: string,
  marginTop: string,
  className: string,
};

ButtonWithLoader.defaultProps = {
  onClick: () => {},
  isLoading: false,
  width: '100%',
  height: '48px',
  bgColor: 'green',
  disabled: false,
  minHeight: undefined,
  marginTop: undefined,
  className: undefined,
};

export default ButtonWithLoader;
