import styled from 'styled-components';

export const AppStoreLinkIcon = styled.img`
    width: 166px;
    height: 48px;
`;

export const GooglePlayLinkIcon = styled.img`
    width: 166px;
    height: 48px;
`;
