import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Flex } from '@components';
import { func } from 'prop-types';
import { STORE_NAMES } from '@constants';
import { STRIPE_PRODUCT_TYPES, TRACKING_PRODUCT_TYPES } from '@constants/common';

import { SubscriptionsCarousel, SubscriptionsTable } from './components';

const SubscriptionsTabDesktop = ({ trackList }) => {
  const { availableProducts: { subscriptions } } = useSelector(store => store[STORE_NAMES.PRODUCTS]);
  const { authorization_info: { id: customerId } } = useSelector(store => store[STORE_NAMES.AUTH]);

  const [SubscriptionsSlider, setSubscriptionsSlider] = useState(null);
  const [TableSlider, setTableSlider] = useState(null);
  const isListTracked = useRef(false);

  useEffect(() => {
    if (subscriptions.length !== 0 && !isListTracked.current) {
      trackList({
        products: subscriptions.map(sub => ({
          ...sub,
          stripe_product_type: STRIPE_PRODUCT_TYPES.SUBSCRIPTION,
        })),
        customerId,
        type: TRACKING_PRODUCT_TYPES.subscription,
      });
      isListTracked.current = true;
    }
  }, [subscriptions]);

  return (
    <Flex column marginBottom="xl4">
      <SubscriptionsCarousel
        subscriptions={subscriptions}
        attachedSlider={TableSlider}
        setSubscriptionsSlider={setSubscriptionsSlider}
      />
      <SubscriptionsTable
        subscriptions={subscriptions}
        attachedSlider={SubscriptionsSlider}
        setTableSlider={setTableSlider}
      />
    </Flex>
  );
};

SubscriptionsTabDesktop.propTypes = {
  trackList: func.isRequired,
};

export default SubscriptionsTabDesktop;
