import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Typography, Icon } from '@components';

import { ReactComponent as NorwayFlag } from '@assets/NorwayFlagIcon.svg';
import { ReactComponent as UnitedKingdomFlag } from '@assets/UnitedKingdomFlag.svg';
import styled from 'styled-components';
import { setUserLanguage } from '@utils/storage';
import { oneOf } from 'prop-types';

const layoutByVariant = {
  full: {
    title: 'AuthCommonContent.SwitchLanguage',
    color: 'greyApple',
  },
  short: {
    title: 'LanguageSwitcher.Language',
    color: 'darkSecondary',
  },
};

const LanguageSwitcher = ({ variant }) => {
  const { t, i18n } = useTranslation();

  const handleSwitchLanguage = () => {
    const selectedLng = i18n.language === 'en' ? 'no' : 'en';
    i18n.changeLanguage(selectedLng);
    setUserLanguage(selectedLng);
  };

  const layout = layoutByVariant[variant];

  return (
    <Flex
      onClick={handleSwitchLanguage}
      grow="0"
      cursor="pointer"
      height="48px"
      itemsCenter
    >
      <Typography marginRight="sm" size="md" color={layout.color}>{t(layout.title)}</Typography>
      <IconWrapper borderRadius="xs">
        <Icon SVG={i18n.language === 'en' ? NorwayFlag : UnitedKingdomFlag} width="24px" height="18px" />
      </IconWrapper>
    </Flex>
  );
};

LanguageSwitcher.defaultProps = {
  variant: 'full',
};

LanguageSwitcher.propTypes = {
  variant: oneOf(['full', 'short']),
};

const IconWrapper = styled(Flex)`
    overflow: hidden;
`;

export default LanguageSwitcher;
