import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { marginMixin, paddingMixin } from '@mixins';

const {
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
} = marginMixin;

const {
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
} = paddingMixin;

const Text = styled(Link)`
${({
    color,
    size,
    weight,
    textAlign,
    height,
    underline,
    theme: {
      color: colorFromTheme,
      typography,
    },
  }) => css`
  ${margin}
  ${marginTop}
  ${marginRight}
  ${marginBottom}
  ${marginLeft}

  ${padding}
  ${paddingTop}
  ${paddingRight}
  ${paddingBottom}
  ${paddingLeft}

  ${textAlign && `
    text-align: ${textAlign};
  `}

  ${color && `
    color: ${colorFromTheme[color]};
  `}

  ${size && `
    font-size: ${typography.size[size]};
  `}

  ${weight && `
    font-weight: ${typography.weight[weight]};
  `}

  ${height && `
    height: ${height};
  `}

  font-family: ${typography.family};
  text-decoration: ${underline ? 'underline' : 'none'};
  `}
  
`;

const Button = styled(Text)`
  ${({
    bgColor,
    height,
    borderColor,
    width,
    theme: {
      color: colorFromTheme,
      border,
    },
  }) => css`

  display: flex;
  justify-content: center;
  align-items: center;

  width: ${width || '100%'};
  height: ${height || '48px'};
  background-color: ${colorFromTheme[bgColor]};

  ${borderColor && `
    border: 1px solid ${colorFromTheme[borderColor]};
  `}

  border-radius: ${border.radius.md};
  line-height: ${height || '48px'};
  `
}
`;

const Links = {
  Text,
  Button,
};

export default Links;
