import styled from 'styled-components';
import { Flex } from '@components';
import { oneOf, shape } from 'prop-types';
import React from 'react';

const Divider = ({ type, style }) => (
  <StyledDivider style={style} type={type} />
);

Divider.defaultProps = {
  type: 'horizontal',
  style: {},
};

Divider.propTypes = {
  type: oneOf(['vertical', 'horizontal']),
  style: shape({}),
};

const StyledDivider = styled(Flex)`
  border-bottom: 1px solid  rgba(107, 120, 136, 0.1);
  width: 100%;
  height: 1px;
  flex: 0;

  ${({ type }) => (
    type === 'vertical' && `
       transform: rotate(180deg);
       height: 100%;
       width: 1px;
       border-right: 1px solid  rgba(107, 120, 136, 0.1);
       border-bottom: none;
    `
  )}
`;

export default Divider;
