import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  Flex, Header, Icon, Typography,
} from '@components';
import { useWindowSize } from '@hooks';
import { routes } from '@utils';
import { showErrorToast } from '@utils/helpers';
import { STORE_NAMES } from '@constants';
import { STRIPE_PRODUCT_TYPES } from '@constants/common';
import { ReactComponent as ArrowBack } from '@assets/ArrowBack.svg';

import { PaymentBlock, ProductBlock } from './components';
import { getAllSubscriptions } from '../../store/products/duck';

const ConsumableProduct = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isMobile, isDesktop] = useWindowSize();

  const { availableProducts: { consumable_products } } = useSelector(store => store[STORE_NAMES.PRODUCTS]);
  const { authorization_info: { has_subscription } } = useSelector(store => store[STORE_NAMES.AUTH]);
  const [choosenProduct, setChoosenProduct] = useState({});
  const isProductsFetched = useRef(false);

  const handleGoBack = () => {
    history.push(routes.subscriptions);
  };

  useEffect(async () => {
    if (consumable_products.length === 0 && !isProductsFetched.current) {
      await dispatch(getAllSubscriptions());
      isProductsFetched.current = true;
    } else {
      const foundProduct = consumable_products.find(product => product.id === +id);
      if (!foundProduct) {
        history.push(routes.notFound);
      }
      setChoosenProduct(foundProduct);
    }
  }, [consumable_products]);

  useEffect(() => {
    if (!has_subscription) {
      showErrorToast(t('Toast.NoSubscription'));
      history.push(routes.subscriptions);
    }
  }, [has_subscription]);

  return (
    <Flex column>
      {isDesktop && <Header />}
      <Flex
        paddingLeft={isMobile ? 'xl' : 'xl10'}
        paddingRight={isMobile ? 'xl' : 'xl10'}
        justifyCenter
      >
        <Flex column marginTop="56px" maxWidth={isDesktop ? '1440px' : '600px'}>
          <Flex itemsCenter marginBottom="xl7">
            <Flex grow="0" cursor="pointer" onClick={handleGoBack}>
              <Icon SVG={ArrowBack} />
            </Flex>
            {isDesktop && (
              <Typography
                size="xl2"
                color="darkSecondary"
                marginLeft="md"
              >
                {t('GetProduct.Title')}
              </Typography>
            )}
          </Flex>
          <Flex column={!isDesktop}>
            <ProductBlock product={choosenProduct} />
            <PaymentBlock
              productType={STRIPE_PRODUCT_TYPES.CONSUMABLE}
              productId={choosenProduct.id}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ConsumableProduct;
